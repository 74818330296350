import { Box, Fade, Modal } from "@mui/material";
import ViewPDF from "./PDFviewer";

export const PDFModal = ({ openModal, handleCloseModal, formValues }) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={openModal} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bgcolor: "whitesmoke",
            transform: "translate(-50%, -50%)",
            boxShadow: 24,
            width: "auto",
            height: "auto",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ViewPDF pdfUrl={formValues} />
        </Box>
      </Fade>
    </Modal>
  );
};
