import React, { useContext } from "react";
import Menu from "@mui/material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { useNavigate } from "react-router-dom";
import { NotificationContext } from "../../context/NotificationContext";

const Notification = ({
  anchorEl,
  handleNotificationClick,
  handleNotificationClose,
}) => {
  const { t } = useAuth();
  const navigate = useNavigate();
  const { notifications, setNotifications } =
    useContext(NotificationContext);


  const getAvatarUrl = (name) => {
    if (!name) return '';
    const initials = name
      .split(" ")
      .map((word) => word[0])
      .join("");
    return `https://ui-avatars.com/api/?name=${initials}&background=random&color=fff`;
  };

  const handleNotificationClickInternal = async (notification) => {
    if (!notification.notificationId) return;

    if (!notification.isRead) {
      try {
        await apiAuth.put(
          `/OKR/UpdateNotification/${notification.notificationId}`,
          { isRead: true }
        );
        setNotifications(
          notifications.map((n) =>
            n.notificationId === notification.notificationId
              ? { ...n, isRead: true }
              : n
          )
        );
      } catch (error) {
        console.error("Error updating notification:", error);
      }
    }

    switch (notification.type) {
      case "OKR":
        navigate("/okr");
        break;
      case "Event":
        navigate("/events");
        break;
      default:
        navigate("/");
    }

    handleNotificationClose();
  };

  return (
    <Box sx={{mr: 1}}>
      <Tooltip title={t("notification")}>
        <span>
          <IconButton
            size="large"
            edge="end"
            aria-haspopup="true"
            onClick={handleNotificationClick}
            color="info"
            disabled={notifications.length <= 0}
          >
            <Badge badgeContent={notifications.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          handleNotificationClose();
        }}
        PaperProps={{
          style: {
            width: "600px",
            maxHeight: "450px",
          },
        }}
      >
        <List sx={{ maxHeight: '80vh' }}>
          {notifications.length > 0 ? notifications.map((notification, index) => (
            <ListItem
              key={`notification-${index}`}
              onClick={() => handleNotificationClickInternal(notification)}
              sx={{
                padding: "16px",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={getAvatarUrl(notification.createdByUserId)}
                  alt={notification.createdByUserId}
                />
              </ListItemAvatar>
              <ListItemText
                primary={notification.message}
                sx={{
                  fontSize: "1.25rem",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                }}
              />
            </ListItem>
          )) : (
            <ListItem
              sx={{
                padding: "16px",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemText
                primary={t('noNotificationFound')}
                sx={{
                  fontSize: "1.25rem",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "100%",
                  whiteSpace: "normal",
                }}
              />
            </ListItem>
          )}
        </List>
      </Menu>
    </Box>
  );
};

export default Notification;
