import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      semesterWait: "Waiting",
      systemError: "System error acquired, please try again",
      emailIsInvalid: "Email is invalid or not exist",
      welcome: "Welcome",
      userDeactivated: "User has been deactivated in the system",
      language: "Language",
      profile: "User Profile",
      en: "English",
      vi: "Vietnamese",
      system: "System Language",
      email: "Email Address",
      fname: "First Name",
      lname: "Last Name",
      loginSuccess: "Welcome {{user}}",
      noUserFound: "User is not found",
      validate: "Validate failed, please try again",
      tokenExpired: "Token has been expired, please re-login",
      langChange: "Changed to English",
      errors: {
        404: "The page you are looking for does not exist.",
        401: "You are not authorized to view this page. Please log in to continue.",
        403: "You do not have permission to view this page.",
        required: "Required",
        passNotMatch: "New Password and Confirmed Password are not match",
        emailValidate: "Wrong Email Format",
        passwordRegex:
          "Password must have at least 1 lower letter, 1 upper letter, 1 digit, 1 special character and its length at least 8 characters",
      },
      ipStrictError:
        "You do not have permission to attend. Please check your IP address.",
      drawerItems: {
        dashboard: "Dashboard",
        home: "Home",
        event: "Event",
        report: "Report",
        ticket: "Ticket",
        okr: "OKR",
        candidate: "Candidate Intern",
        importIntern: "Import Intern",
        importLab: "Import Laboratory",
        importMentor: "Import Mentor",
        attendance: "Attendance",
        semester: "Semester",
        dashboardLab: "Dashboard Lab",
        members: "Members",
        copyright: `@${new Date().getFullYear()} Copyright by SEP490-G29 and DXLab`
      },
      userProfile: {
        receiveEmailNotify: "Send Notification To Email",
        confirmPassword: "Confirm Password",
        newPassword: "New Password",
        fileUpload: "Drop or click to upload file",
        btnUpdate: "Update Profile",
        btnSave: "Save Changes",
        btnCancel: "Cancel",
      },
      notification: "Notifications",
      account: "Account",
      updateSuccess: "Updated Successfully",
      pageModule: {
        page: "Page",
        pagePrev: "Previous Page",
        pageNext: "Next Page",
      },
      otpSent: "OTP sent, please check your email",
      checkInDone: "Checked in successfully",
      wrongIpMac:
        "Invalid IP/Mac address, please contact to Lab Manager or check your connection/device",
      wrongFaceVector: "Invalid Face Vector",
      wrongOtp: "Invalid OTP, please try again or re-send OTP",
      noLabFound: "Lab is not found",
      notInTime: "Action is not available now",
      checkInExisted: "Checked in, cannot check in again",
      checkOutDone: "Checked out successfully",
      otpSent5min: "OTP sent, please try again after 5 minutes",
      getOtp: "Get OTP",
      faceScan: "Face Scanning",
      otp: "OTP",
      approve: "Approve",
      attendanceStatus: {
        1: "Attended",
        2: "Late In",
        3: "Soon Out",
        4: "Absent",
      },
      importMentor: {
        selectFile: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        importSuccess: "Created Mentor Successfully",
        emailIsNull: "Email field is null",
        emailIsNotValid: "Email is invalid or not existed",
        emailIsExistInDatabase: "Email is existed in system",
        emailNotExist: "Email is not exist",
        mentorAddSuccess: "Added Mentor Successfully"
      },
      importIntern: {
        emailNotExist: "Email is not exist",
        selectFileToImport: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        uploadAndSendMailSuccess:
          "Uploaded Candidate Interns and Send Mail Successfully",
        failedToLoad: "Failed to load, please try again",
        labNameNotExist: "Lab name is not exist",
        rollNumberIsNull: "Roll Number is null",
        dobIsNull: "Date of birth is null",
        emailIsNull: "Email is null",
        invalidEmailFormat: "Email is invalid or not existed",
        emailIsExistInDataBase: "Email is existed in system",
        labNameIsNull: "Lab Name is null",
        importFailed: "Import Failed",
        status: {
          true: "Succeed",
          false: "Failed",
        },
      },
      importLab: {
        selectFileToImport: "Please select a file before submitting",
        invalidFileFormat: "Invalid File Format, make sure it's .csv file",
        rowsAreInvalid: "Data rows are invalid, please check them below",
        invalidHeader: "Invalid header, please check csv file",
        failedToLoad: "Failed to load, please try again",
        labNameIsExist: "Lab name is existed",
        userExisted: "User is existed",
        labManagerEmailIsNull: "Lab Manager email is null",
        uploadSuccess: "Uploaded Successfully",
        labManagerEmailIsInvalid: "Email is invalid or not existed",
        labNameIsNull: "Lab Name is null",
        importFailed: "Import Failed",
      },
      candidate: {
        updateSuccess: "Update Succeed",
        assignSuccess: "Assign Succeed",
        failedToCreateIdentityUser: "Failed to create intern, please try again",
        notFound: "No resource found",
        noUserFound: "User not found",
        candidateWaiting: "Candidate interns waiting to approve",
        candidateAssign: "Candidate interns waiting to assign",
        table: {
          firstName: "First Name",
          lastName: "Last Name",
          email: "Email Address",
          actions: "Actions",
          assignTo: "Assign To",
          noCandidate: "No Candidate Found",
          candidateCv: "Candidate CV",
          approve: "Approve",
          reject: "Reject",
          labName: "Lab Name",
          showCv: "Show CV",
        },
        search: "Search by first name, last name and email",
        cvNotFound: "CV not found",
      },
      noNotificationFound: "No notification(s) found",
      notFoundInformation: "No information found",
      okrStatus: {
        1: "Not Started",
        2: "In Progress",
        3: "Completed",
        4: "Stuck",
      },
      okr: {
        okr: "OKR Module",
        main_Table: "Main Table",
        timeline: "Timeline",
        dashboard: "Dashboard",
        log_Dialog: "OKR Logs",
        team_Dialog: "Team Management",
        add_O_Button: "Add Objective",
        add_kr_Button: "New Key Result",
        search: "Search",
        filter_Priority: "Filter Priority",
        filter_1: "All",
        filter_2: "High",
        filter_3: "Medium",
        filter_4: "Low",
        sort_By: "Sort By",
        sort_1: "Title",
        sort_2: "Priority",
        sort_3: "Status",
        title: "Title",
        assign_to: "Assigned To",
        assign_to_def: "Not Assigned",
        priority: "Priority",
        progress: "Progress",
        estimate_Man_Day: "Estimate Man Day",
        actual_Man_Day: "Actual Man Day",
        difference: "Difference (Est. vs Act.)",
        notes: "Notes",
        attached_Files: "Attached Files",
        no_Commnet: "No comments yet.",
        comments: "Comments",
        reply: "Reply",
        comment_Error: "Comment content cannot be empty.",
        reply_Error: "Reply content cannot be empty.",
        actions: "Actions",
        save: "Save",
        update: "Update",
        cancel: "Cancel",
        delete: "Delete",
        description: "Description",
        start_Date: "Start Date",
        end_Date: "End Date",
        status: "Status",
        approved: "Is Approved",
        error_title: "Title is required",
        error_description: "Description is required",
        error_startDate: "Start Date is required",
        error_endDate: "End Date is required",
        error_ManDay: "Must be a positive number",
        error_endBeforeStart: "Start date need before End date",
        error_startDateInPast:"Start date cannot be in the past.",
        error_endDateInPast:"End date cannot be in the past.",
        errordateInPast:"Date time cannot be in past",
        rf_con: "Are you sure you want to refresh?",
        del_con: "Are you want to delete this",
        t_search: "Search by name or email",
        t_title: "Invite board members",
        t_invite: "Invite new member by email",
        t_b_invite: "Invite",
        t_b_close: "Close",
        l_title: "Activity Log",
        l_default: "No logs available.",
        ch_kr: "Key Results Progress",
        ch_o: "Objective Progress",
        ch_o_1: "Objectives",
        ch_o_2: "Progress (%)",
        ch_pd: "Priority Distribution",
        ch_c_t: "Current Value vs. Target Value",
        ch_pp: "Progress Performance",
        ch_pp_1: "Report Date",
        ch_pp_2: "Value",
        select_team: "Select Team",
        tl_Duration: "Duration",
        toast_add_o_s: "Objective added successfully!",
        toast_add_o_f: "Failed to add objective.",
        toast_up_o_s: "Objective updated successfully!",
        toast_up_o_f: "Failed to update objective.",
        toast_del_o_s: "Objective deleted successfully!",
        toast_del_o_f: "Failed to delete Objective.",
        toast_add_kr_s: "Key Result added successfully!",
        toast_add_kr_f: "Failed to add Key Result.",
        toast_up_kr_s: "Key Result updated successfully!",
        toast_del_kr_s: "Key Result deleted successfully!",
        toast_save_kr_f: "Failed to save Key Result.",
        toast_del_kr_f: "Failed to delete Key Result.",
        toast_add_kr_wr: "You can only add Key Results to approved objectives.",
        assign_default: "Not Assigned",
      },
      event: {
        eventsNotFound: "Event(s) not found",
        attendance: "Attendance",
        delete: "Delete",
        take_Attendance: "Take Attendance",
        save: "Save",
        cancel: "Cancel",
        edit: "Edit",
        close: "Close",
        update_Event: "Update Event",
        event_Details: "Event Details",
        add_Event: "Add Event",
        select_Users: "Select Users",
        roll: "Roll Name",
        name: "Name",
        avatar: "Avatar",
        e_Attendance_Status1: "Attended",
        e_Attendance_Status2: "Late",
        e_Attendance_Status3: "Absent",
        title: "Event Title",
        description: "Description",
        start_Date: "Start Date",
        end_Date: "End Date",
        start_Time: "Start Time",
        location: "Location",
        end_Time: "End Time",
        start: "Start at",
        end: "End at",
        toast_NotFound: "Not Found",
        toast_Event_add_s: "Event added successfully!",
        toast_Event_add_f: "Event added failed.",
        toast_Event_up_s: "Event updated successfully!",
        toast_Event_up_f: "Event updated failed.",
        toast_Event_del_s: "Event deleted successfully!",
        toast_Event_attendance_s: "Attendance recorded successfully!",
        toast_Event_attendance_f: "Attendance recorded failed.",
        toast_Event_datevalidate:
          "Start date and time must be earlier than end date and time.",
          toast_Event_past_date_error:"Event must start after present"
      },
      report: {
        internDetail: {
          title: "Intern Details",
          name: "Full Name",
          email: "Email Address",
          lab: "Lab",
          mentor: "Mentor",
          rollName: "Roll Name"
        },
        failed: "Failed",
        addReport: "Add Report",
        commentOfCompany: "Comment of Mentor",
        majorSkill: "Major Skill",
        softSkill: "Soft Skill",
        attitude: "Attitude",
        back: "Back",
        keyResultsOverview: "Key Results Overview",
        attendanceOverview: "Attendance Overview",
        toggleChartType: "Toggle Chart Type",
        attended: "Attended",
        absent: "Absent",
        lateIn: "Late In",
        soonOut: "Soon Out",
        inProgress: "In Progress",
        completed: "Completed",
        notStarted: "Not Started",
      },
      reportV: {
        updateSemester: "Update Semester Successfully",
        listOfLab: "List of laboratories",
        labName: "Lab Name",
        listOfSemesters: "List of Semesters",
        semesterID: "Semester ID",
        semesterName: "Semester Name",
        addSemester: "Add Semester",
        sd: "Start Date",
        ed: "End Date",
        ac: "Action",
        viewIntern: "View Intern",
        internsforSemester: "Interns for Semester",
        rollName: "Roll Name",
        email: "Email Address",
        lstMentor: "List of Mentors",
        nameM: "Mentor Name",
        nameI: "Name Intern",
        noLab: "No labs are found",
        noM: "No mentors found",
        noi: "No interns found for this semester.",
        close: "Close",
        exporttoExcel: "Export to Excel final",
        exporttoExcel1: "Export to Excel midterm",
        searchbyRollName: "Search by Roll Name",
        exportSuccess: "Report is exported successfully",
        search: "Search",
        rID: "Report ID",
        sID: "Staff ID",
        lName: "Lab Name",
        mName: "Mentor Name",
        allowance: "Allowance",
        finalResult: "Final Result",
        delete: "Delete",
        dashBoard: "Performance summary",
        internsList: "Interns List",
        totalObjectives: "Total Objectives",
        createdDate: "Created Date",
        excelNotFound: "Resource not found.",
        btnAdd: "Save",
        sAddSucc: "Semester added successfully!",
        sExcel400: "Error generating Excel file. Please try again.",
        sUnex: "Unexpected response structure.",
        sDelete: "Are you sure you want to delete this report?",
        sDeleteSuc: "Report deleted successfully.",
        sSearch: "No reports found for the given Roll Name.",
        aReportSuc: "Report added successfully!",
        aInvail: "Invalid report data.",
        errors: {
          400: "Report information is required.",
          409: "A report for this period already exists.",
          422: "Invalid report data.",
          500: "The semester has ended. Reports cannot be added after the semester period.",
        },
        unexpectedError:
          "An unexpected error occurred. Please check your connection.",
        reportAddTime:
          "Reports can only be added between {{startMidterm}} and {{endMidterm}}, or between {{startEnd}} and {{endEnd}}.",
        errors1: {
          400: "Bad request. Please check the input data.",
          409: "No interns found for this semester.",
        },
        edit: "Edit"
      },
      attendance:{
        date: "Attendance Date: {{date}}",
        updateAttendance: "Update Intern Attendance",
        invalidTime: "Invalid setting times",
        cleanBatch: "Resetted attendance records",
        checkIn: "Check In",
        checkOut: "Check Out",
        test:"Error fetching attendance data",
        updateSuccess:"Update Success",
        updateFailse:"Update Failse",
        validateTimeCheckInCheckOut :"Time check-in must below time check-out",
        pageNotExist:"Page Not Exits",
        noUserFound:"No User Found",
        notFoundInformation : "Not Found Information",
        otpSent5min:"OTP sent 5 min",
        wrongIpMac:"Wrong Ip Mac",
        otpSent:"Otp Sent",
        wrongFaceVector:"Wrong Face Vector",
        wrongOtp:"Wrong Otp",
        noLabFound:"No Lab Found",
        checkInDone : "Check-In Done",
        notInTime:"Not In Time",
        checkInExisted:"Check-In Existed",
        checkOutDone:"Check-Out Done",
        notCheckInYet:"Not Check-In Yet",
        statusBatchNullOrEmpty:"Status Batch Null Or Empty",
        batchEnd:"Success, attendance batch ended",
        successBatch:"Success, attendance batch on",
        checkBatch:"Attendance batch is stopped. Happy Holiday/Weekend!",
        rollName:"Roll Name",
        name:"Name",
        email:"Email",
        absent:"Absent",
        attended:"Attended",
        lateIn:"Late In",
        soonOut:"Soon Out",
        actions:"Actions",
        search:"Search",
        month:"Month",
        year:"Year",
        otAttendance:"Open Ticket Attendance",
        c_t_Attendance:"Create Tickets Attendance",
        attendanceList:"Attendance List",
        noRecordsFound:"No records found",
      },
      ticket:{
        statusText: {
          1: "Pending",
          2: "Approved",
          3: "In Progress",
          4: "Done",
          5: "Reject",
          all: "All"
        },
        createSuccessTicket: "Created Ticket Successfully",
        ticketNotFound: "No ticket found",
        titleAndDateCannotBeBlank:"Title and Date cannot be blank.",
        validateDate:"Please select a valid date (today or later).",
        titleIsRequired: "Title is required",
        ticketList:"Ticket List",
        confirmDelete:"Confirm Delete",
        toDeleteThisTicket:" Are you sure you want to delete this ticket?",
        delete:"Delete",
        edit:"Edit",
        saveChanges:"Save Changes",
        ticketDetails:"Ticket Details",
        ticketComments:"Ticket Comments",
        comment:"Comment",
        noDescription:"No description",
        cancel:"Cancel",
        create:"Create",
        createTicketAttendance:"Create Ticket Attendance",
        createDate:"Create Date",
        createTicket:"Create Ticket",
        details:"Details",
        approve:"Approve",
        reject:"Reject",
        iD:"ID",
        creator	:"Creator",
        assignee	:"Assignee",
        title	:"Title",
        description	:"Description",
        dateRequire :"Date Require",
        status	:"Status",
        actions:"Actions",
        date:"Date",
        checkInTime:"Check-In Time",
        checkOutTime:"Check-Out Time",
        reason:"Reason",
        listAttendanceTicket:"List Attendance Ticket",
        needLoginFirst:"Need Login First!",
        notFoundTickets:"Tickets Not Found",
        checkPermissionToView:"You don't has permission to view ticket attendance",
        notFoundCommentTicket:"Not Found Comment Ticket!",
        updateTicketSuccessfully:"Update Ticket Successfully!",
        descriptionEmpty:"Description Empty",
        commentSuccess:"Comment Success",
        spamComment:"Spam Comment",
        inputEmpty:"Input Empty",
        approveTicketSuccessfully:"Approve Ticket Successfully",
        rejectTicketSuccessfully:"Reject Ticket Successfully",
        dontHaveLabInfor:"User does not have lab information yet",
        createSuccessTicketAttendance:"Create success ticket Attendance.",
        createFaileTicketAttendance:"Can't not create ticket Attendance! Try again.",
        dontHaveMentor:"User don't have mentor",
        duplicateTicket:"Duplicate Ticket",
        notFoundLabManager:"Not found Lab Manager!",
        createTicketFailse:"Create Ticket Failse!",
        updateTicketFailse:"Update Ticket Failse",
        success:"Success",
        failseAPI:"Error fetching tickets.",
        close:"Close",
      },
      settingLab:{
        timeRequired:"Time Required",
        nothingChange:"Nothing Change",
        cancel:"Cancel",
        save:"Save",
        leaveRequestNum:"Leave Request Num",
        noDayOffsAvailable:"No day offs available",
        settingDayOff:"Setting DayOff",
        settingTimeLab:"Setting Time Lab",
        leaveRequestNumValidate:"Leave Request Num must be greater than 0",
        theDayOffDateAlreadyExists:"The day off date already exists.",
        CreatedSuccess:"Dayoff created successfully!",
        CreateFailse:"DayOff Create Failse",
        noDayOffFound:"No DayOff Found",
        deleteDayOffSuccess:"Delete DayOff Success",
        checkInTime:"Check-In Time",
        checkOutTime:"Check-Out Time",
      },
      currentSemester: {
        name: "Current Semester: {{name}}",
        period: "Start Date: {{startDate}} - End Date: {{endDate}}",
      }
    },
  },
  vi: {
    translation: {
      semesterWait: "Đang chờ",
      systemError: "Lỗi hệ thống xảy ra, xin vui lòng thử lại",
      emailIsInvalid: "Email sai hoặc không tồn tại",
      welcome: "Chào mừng",
      userDeactivated: "Người dùng không còn hoạt động trong hệ thống",
      language: "Ngôn ngữ",
      profile: "Thông tin người dùng",
      en: "Tiếng Anh",
      vi: "Tiếng Việt",
      system: "Ngôn ngữ hệ thống",
      email: "Địa chỉ email",
      fname: "Họ",
      lname: "Tên",
      loginSuccess: "Chào mừng quay trở lại",
      noUserFound: "Người dùng không tồn tại",
      validate: "Xác thực thất bại, vui lòng thử lại",
      tokenExpired: "Token đã hết hạn, vui lòng đăng nhập lại",
      langChange: "Đã đổi sang tiếng Việt",
      errors: {
        404: "Trang không tồn tại",
        401: "Bạn không được phép xem trang này, hãy đăng nhập để tiếp tục",
        403: "Bạn không có quyền xem trang này",
        required: "Bắt buộc",
        passNotMatch: "Mật khẩu mới và mật khẩu xác nhận không giống nhau",
        emailValidate: "Sai định dạng email",
        passwordRegex:
          "Mật khẩu cần có ít nhất 1 chữ thường, 1 chữ in hoa, 1 ký tự số, 1 ký tự đặc biệt và dài ít nhất 8 ký tự",
      },
      drawerItems: {
        dashboard: "Dashboard",
        home: "Trang chủ",
        event: "Sự kiện",
        report: "Báo cáo",
        ticket: "Phiếu hỗ trợ",
        okr: "Mục tiêu và kết quả",
        candidate: "Ứng viên",
        importIntern: "Nhập thực tập sinh",
        importLab: "Nhập Phòng Lab",
        importMentor: "Nhập Mentor",
        attendance: "Điểm danh",
        semester: "Kỳ học",
        dashboardLab: "Dashboard Quản lý",
        members: "Thành viên",
        copyright: `@${new Date().getFullYear()} Bản quyền thuộc về SEP490-G29 và DXLab`
      },
      userProfile: {
        receiveEmailNotify: "Gửi Thông Báo Qua Email",
        confirmPassword: "Xác nhận mật khẩu",
        newPassword: "Mật khẩu mới",
        fileUpload: "Thả hoặc nhấn vào để tải lên tệp",
        btnUpdate: "Cập nhật thông tin",
        btnSave: "Lưu thay đổi",
        btnCancel: "Hủy bỏ",
      },
      ipStrictError:
        "Bạn không có quyền để điểm danh. Hãy kiểm tra lại địa chỉ IP của bạn.",
      notification: "Thông báo",
      account: "Tài khoản",
      updateSuccess: "Cập nhật thành công",
      pageModule: {
        page: "Trang",
        pagePrev: "Trang trước",
        pageNext: "Trang sau",
      },
      otpSent: "Mã OTP đã được gửi, hãy kiểm tra email của bạn",
      checkInDone: "Điểm danh vào thành công",
      wrongIpMac:
        "Địa chỉ IP/Mac không hợp lệ, hãy liên hệ người quản lý phòng lab hoặc kiểm tra lại thiết bị/mạng của bạn",
      wrongFaceVector: "Vector khuôn mặt không hợp lệ",
      wrongOtp: "OTP không hợp lệ, hãy nhập lại hoặc gửi lại mã",
      noLabFound: "Phòng Lab không tồn tại",
      notInTime: "Hành động này không khả dụng ở hiện tại",
      checkInExisted: "Đã điểm danh vào, không thể điểm danh thêm",
      checkOutDone: "Điểm danh ra thành công",
      otpSent5min: "Mã OTP đã được gửi, hãy thử lại sau 5 phút",
      getOtp: "Lấy mã OTP",
      faceScan: "Quét khuôn mặt",
      otp: "OTP",
      approve: "Xác nhận",
      okrStatus: {
        1: "Chưa bắt đầu",
        2: "Đang thực hiện",
        3: "Hoàn Thành",
        4: "Mắc kẹt",
      },
      attendanceStatus: {
        1: "Đã điểm danh",
        2: "Vào muộn",
        3: "Ra sớm",
        4: "Vắng mặt",
      },
      importMentor: {
        selectFile: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        importSuccess: "Tạo Mentor thành công",
        emailIsNull: "Trường Email đang bị trống",
        emailIsNotValid: "Email không hợp lệ hoặc không tồn tại",
        emailIsExistInDatabase: "Email đã tồn tại trong hệ thống",
        mentorAddSuccess: "Thêm người hướng dẫn thành công"
      },
      importIntern: {
        emailNotExist: "Emali không tồn tại",
        selectFileToImport: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        uploadAndSendMailSuccess: "Đã thêm ứng viên và gửi mail thành công",
        failedToLoad: "Tải thất bại, vui lòng thử lại",
        labNameNotExist: "Tên lab không tồn tại",
        rollNumberIsNull: "Mã số sinh viên bị trống",
        dobIsNull: "Ngày sinh bị trống",
        emailIsNull: "Trường Email bị trống",
        invalidEmailFormat: "Email không hợp lệ hoặc không tồn tại",
        emailIsExistInDataBase: "Email đã tồn tại trong hệ thống",
        labNameIsNull: "Tên lab bị trống",
        importFailed: "Nhập dữ liệu thất bại",
        status: {
          true: "Hoàn thành",
          false: "Thất bại",
        },
      },
      importLab: {
        selectFileToImport: "Hãy chọn một tập tin trước khi gửi",
        invalidFileFormat: "Sai định dạng tập tin, hãy chắc chắn là tệp .csv",
        rowsAreInvalid: "Dữ liệu sai, xin vui lòng kiểm tra bên dưới",
        invalidHeader: "Sai tên header, hãy kiểm tra lại tệp tin csv",
        failedToLoad: "Tải thất bại, vui lòng thử lại",
        labNameIsExist: "Tên phòng nghiêm cứu đã tồn tại",
        userExisted: "Người dùng đã tồn tại",
        labManagerEmailIsNull: "Email Quản lý phòng nghiêm cứu trống",
        uploadSuccess: "Tải lên thành công",
        labManagerEmailIsInvalid: "Email sai định dạng hoặc không tồn tại",
        labNameIsNull: "Tên phòng nghiêm cứu trống",
        importFailed: "Nhập thất bại",
      },
      candidate: {
        updateSuccess: "Cập nhật thành công",
        assignSuccess: "Bàn giao thành công",
        failedToCreateIdentityUser:
          "Tạo thực tập sinh thất bại, vui lòng kiểm tra lại dữ liệu",
        notFound: "Không tìm thấy tài nguyên",
        noUserFound: "Không tìm thấy người dùng",
        candidateWaiting: "Ứng viên tạm thời đang chờ phê duyệt",
        candidateAssign: "Ứng viên tạm thời đang chờ được bàn giao",
        table: {
          firstName: "Họ",
          lastName: "Tên",
          email: "Địa chỉ Email",
          actions: "Hành động",
          assignTo: "Bàn giao tới",
          noCandidate: "Không tìm thấy ứng viên",
          candidateCv: "CV của ứng viên",
          approve: "Chấp thuận",
          reject: "Từ chối",
          labName: "Tên Lab",
          showCv: "Xem CV",
        },
        search: "Tìm kiếm bằng họ tên và email",
        cvNotFound: "Không tìm thấy CV",
      },
      noNotificationFound: "Không có thông báo",
      notFoundInformation: "Không tìm thấy thông tin",
      okr: {
        okr: "Các mục tiêu và kết quả then chốt",
        main_Table: "Màn hình chính",
        timeline: "Mốc thời gian",
        dashboard: "Bảng biểu đồ",
        log_Dialog: "Nhật ký",
        team_Dialog: "Quản lý nhóm",
        add_O_Button: "Thêm mục tiêu",
        add_kr_Button: "kết quả mới",
        search: "Tìm kiếm",
        filter_Priority: "Lọc theo độ quan trọng",
        filter_1: "Toàn bộ",
        filter_2: "Cao",
        filter_3: "Trung bình",
        filter_4: "Thấp",
        sort_By: "Sắp xếp theo",
        sort_1: "Tiêu đề",
        sort_2: "Độ quan trọng",
        sort_3: "Trạng thái",
        title: "Tiêu Đề",
        assign_to: "Giao cho",
        assign_to_def: "Chưa giao",
        priority: "Độ quan trọng",
        progress: "Tiến độ",
        estimate_Man_Day: "Số ngày dự kiến",
        actual_Man_Day: "Số ngày thực tế",
        difference: "Khác biệt giữa dự kiến và thực tế",
        notes: "Ghi chú",
        attached_Files: "Tệp đính kèm (links)",
        no_Commnet: "Hiện chưa có bình luận.",
        comments: "Bình luận",
        reply: "Trả lời",
        comment_Error: "Nội dung của bình luận không thể trống.",
        reply_Error: "Nội dung của trả lời không thể trống.",
        actions: "Hành động",
        save: "Lưu",
        update: "Cập nhật",
        cancel: "Hủy",
        delete: "Xóa",
        description: "Miêu tả",
        start_Date: "Ngày bắt đầu",
        end_Date: "Ngày kết thúc",
        status: "Trạng thái",
        approved: "Chấp thuận",
        error_title: "Tiêu đề là bắt buộc",
        error_description: "Miêu tả là bắt buộc",
        error_startDate: "Ngày bắt đầu là bắt buộc",
        error_endDate: "Ngày kết thúc là bắt buộc",
        error_ManDay: "Nên là số dương",
        error_endBeforeStart: "Ngày bắt đầu nên trước ngày kết thúc",
        error_startDateInPast:"Ngày bắt đầu không thể ở quá khứ",
        error_endDateInPast:"Ngày kết thúc không thể ở quá khứ",
        errordateInPast:"Thời gian không thể ở quá khứ",
        rf_con: "Bạn có muốn làm mới",
        del_con: "Bạn có muốn xóa",
        t_search: "Tìm kiếm bằng tên hoặc email",
        t_title: "Mời thành viên",
        t_invite: "Mời thành viên bằng email",
        t_b_invite: "Mời",
        t_b_close: "Đóng",
        l_title: "Nhật ký hoạt động",
        l_default: "Hiện chưa có nhật ký nào",
        ch_kr: "Tiến độ của kết quả",
        ch_o: "Tiến độ của mục tiêu",
        ch_o_1: "Mục tiêu",
        ch_o_2: "Kết quả (%)",
        ch_pd: "Phân phối của độ quan trọng",
        ch_c_t: "Giá trị hiện tại so với giá trị mục tiêu",
        ch_pp: "Hiệu suất của tiến độ",
        ch_pp_1: "Ngày báo cáo",
        ch_pp_2: "Giá trị",
        select_team: "Chọn nhóm",
        tl_Duration: "Khoảng thời gian",
        toast_add_o_s: "Thêm mục tiêu thành công!",
        toast_add_o_f: "Thêm mục tiêu thất bại.",
        toast_up_o_s: "Cập nhật mục tiêu thành công!",
        toast_up_o_f: "Cập nhật mục tiêu thất bại.",
        toast_del_o_s: "Xóa mục tiêu thành công!",
        toast_del_o_f: "Xóa mục tiêu thất bại.",
        toast_add_kr_s: "Thêm kết quả thành công!",
        toast_add_kr_f: "Thêm kết quả thất bại.",
        toast_up_kr_s: "Cập nhật kết quả thành công!",
        toast_del_kr_s: "Xóa kết quả thành công!",
        toast_save_kr_f: "Lưu kết quả thất bại",
        toast_del_kr_f: "Xóa kết quả thất bại",
        toast_add_kr_wr:
          "Bạn chỉ có thể thêm kết quả cho mục tiêu đã chấp thuận",
        assign_default: "Không phân công",
      },
      event: {
        eventsNotFound: "Không tìm thấy sự kiện",
        attendance: "Tham dự",
        delete: "Xóa",
        take_Attendance: "Điểm danh",
        save: "Lưu",
        cancel: "Hủy",
        edit: "Cập nhật",
        close: "Đóng",
        update_Event: "Cập nhật sự kiện",
        event_Details: "Thông tin sự kiện",
        add_Event: "Thêm sự kiện",
        select_Users: "Lựa chọn người tham gia",
        roll: "Mã sinh viên",
        name: "Tên",
        avatar: "Hình đại diện",
        e_Attendance_Status1: "Tham dự",
        e_Attendance_Status2: "Muộn",
        e_Attendance_Status3: "Nghỉ",
        title: "Tiêu đề sự kiện",
        description: "Miêu tả",
        start_Date: "Ngày bắt đầu",
        end_Date: "Ngày kết thúc",
        start_Time: "Thời gian bắt đầu",
        location: "Địa điểm",
        end_Time: "Thời gian kết thúc",
        start: "Bắt đầu vào",
        end: "Kết thúc lúc",
        toast_NotFound: "Không tìm thấy",
        toast_Event_add_s: "Thêm sự kiện thành công!",
        toast_Event_add_f: "Thêm sự kiện thất bại.",
        toast_Event_up_s: "Cập nhật sự kiện thành công!",
        toast_Event_up_f: "Cập nhật sự kiện thất bại!",
        toast_Event_del_s: "Xóa sự kiện thành công.",
        toast_Event_attendance_s: "Điểm danh thành công!",
        toast_Event_attendance_f: "Điểm danh thất bại",
        toast_Event_datevalidate:
          "Thời gian bắt đầu nên sớm hơn thời gian kết thúc",
          toast_Event_past_date_error:"Sự kiện nên bắt đầu sau hiện tại",
      },
      report: {
        internDetail: {
          title: "Thông tin Thực tập sinh",
          name: "Họ và tên",
          email: "Địa chỉ Email",
          lab: "Phòng Lab",
          mentor: "Người Hướng Dẫn",
          rollName: "MSSV"
        },
        failed: "Thất bại",
        addReport: "Thêm báo cáo",
        commentOfCompany: "Nhận xét của người hướng dẫn",
        majorSkill: "Kỹ năng chuyên ngành",
        softSkill: "Kỹ năng mềm",
        attitude: "Thái độ",
        back: "Trở về",
        keyResultsOverview: "Tổng quan về kết quả chính",
        attendanceOverview: "Tổng quan về điểm danh",
        toggleChartType: "Chuyển đổi loại biểu đồ",
        attended: "Có mặt",
        absent: "Vắng mặt",
        lateIn: "Vào muộn",
        soonOut: "Về sớm",
        inProgress: "Đang tiến hành",
        completed: "Hoàn thành",
        notStarted: "Chưa bắt đầu",
      },
      reportV: {
        updateSemester: "Cập nhật kỳ học thành công",
        listOfLab: "Danh sách phòng lab",
        labName: "Tên phòng lab",
        listOfSemesters: "Danh sách học kỳ",
        semesterID: "ID học kỳ",
        semesterName: "Tên học kỳ",
        addSemester: "Thêm học kỳ",
        sd: "Ngày bắt đầu",
        ed: "Ngày kết thúc",
        ac: "Hành động",
        viewIntern: "Xem thực tập sinh",
        internsforSemester: "Thực tập sinh trong học kỳ",
        rollName: "Mã sinh viên",
        email: "Địa chỉ Email",
        nameI: "Tên thực tập sinh",
        noLab: "Không tìm thấy lab",
        noM: "Không tìm thấy người hướng dẫn",
        noi: "Không tìm thấy thực tập sinh nào trong học kỳ này.",
        close: "Đóng",
        exporttoExcel: "Xuất sang Excel cuối kỳ",
        exporttoExcel1: "Xuất sang Excel giữa kỳ",
        searchbyRollName: "Tìm kiếm theo mã sinh viên",
        exportSuccess: "Xuất báo cáo thành công",
        search: "Tìm kiếm",
        rID: "ID báo cáo",
        sID: "ID nhân viên",
        lName: "Tên phòng nghiên cứu",
        mName: "Tên cố vấn",
        allowance: "Trợ cấp",
        finalResult: "Kết quả cuối cùng",
        delete: "Xóa",
        dashBoard: "Tóm tắt hiệu suất",
        internsList: "Danh Sách thực tập sinh",
        totalObjectives: "Tổng số mục tiêu",
        createdDate: "Ngày tạo",
        excelNotFound: "Không tìm thấy báo cáo",
        sExcel400: "Có lỗi khi tạo tệp Excel. Vui lòng thử lại.",
        btnAdd: "Lưu",
        lstMentor: "Danh sách người hướng dẫn",
        nameM: "Tên người hướng dẫn",
        sUnex: "Cấu trúc phản hồi không mong đợi.",
        sDelete: "Bạn có chắc chắn muốn xóa báo cáo này không?",
        sDeleteSuc: "Báo cáo đã được xóa thành công.",
        sSearch: "Không tìm thấy báo cáo nào cho mã sinh viên đã cho.",
        aReportSuc: "Báo cáo đã được thêm thành công!",
        aInvail: "Dữ liệu báo cáo không hợp lệ.",
        errors1: {
          400: "Yêu cầu không hợp lệ. Vui lòng kiểm tra dữ liệu đầu vào.",
          409: "Không tìm thấy thực tập sinh nào cho học kỳ này.",
        },
        errors: {
          400: "Thông tin báo cáo là bắt buộc.",
          409: "Một báo cáo cho giai đoạn này đã tồn tại.",
          422: "Dữ liệu báo cáo không hợp lệ.",
          500: "Học kỳ đã kết thúc. Không thể thêm báo cáo sau thời gian học kỳ.",
        },
        unexpectedError:
          "Đã xảy ra lỗi bất ngờ. Vui lòng kiểm tra kết nối của bạn.",
        reportAddTime:
          "Báo cáo chỉ có thể được thêm vào giữa {{startMidterm}} và {{endMidterm}}, hoặc giữa {{startEnd}} và {{endEnd}}.",
        edit: "Chỉnh sửa"
      },
      attendance:{
        date: "Ngày điểm danh: {{date}}",
        updateAttendance: "Cập nhật thực tập sinh điểm danh",
        invalidTime: "Điều chỉnh giờ sai",
        cleanBatch: "Đã thiết lập lại bản ghi điểm danh ngày hôm nay",
        checkIn: "Điểm danh vào",
        checkOut: "Điểm danh ra",
        test:"Lỗi khi lấy dữ liệu điểm danh",
        updateSuccess:"Cập nhật thành công",
        updateFailse:"Cập nhật lỗi",
        validateTimeCheckInCheckOut :"Thời gian check-in cần sớm hơn thời gian check-out",
        pageNotExist:"Trang không tồn tại",
        noUserFound:"Không tìm thấy user",
        notFoundInformation : "Không thấy thông tin",
        otpSent5min:"OTP đã được gửi, có hiệu lực trong 5 phút.",
        wrongIpMac:"Địa chỉ IP hoặc MAC không đúng.",
        otpSent:"Otp đã được gửi",
        wrongFaceVector:"Dữ liệu vector khuôn mặt không chính xác.",
        wrongOtp:"Mã OTP không chính xác.",
        noLabFound:"Không tìm thấy phòng lab",
        checkInDone : "Đã hoàn thành Check-In.",
        notInTime:"Không đúng giờ.",
        checkInExisted:"Đã check-in rồi.",
        checkOutDone:"Đã check-out xong.",
        notCheckInYet:"Chưa check-in.",
        statusBatchNullOrEmpty:"Trạng thái batch không hợp lệ hoặc trống.",
        batchEnd:"Thành công, batch điểm danh đã được tắt.",
        successBatch:"Thành công, batch điểm danh đã được bật.",
        checkBatch:"Batch điểm danh đã dừng. Chúc bạn kỳ nghỉ lễ/cuối tuần vui vẻ!",
        rollName:"Mã sinh viên",
        email:"Email",
        name:"Tên",
        absent:"Vắng mặt",
        attended:"Có mặt",
        lateIn:"Đến muộn",
        soonOut:"Về sớm",
        actions:"Hành động",
        search:"Tìm kiếm",
        month:"Tháng",
        year:"Năm",
        otAttendance:"Mở đơn xin điểm danh",
        c_t_Attendance:"Tạo đơn xin điểm danh",
        attendanceList:"Danh sách điểm danh",
        noRecordsFound:"Không tìm thấy bản ghi nào",
      },
      ticket:{
        statusText: {
          1: "Đang chờ",
          2: "Chấp thuận",
          3: "Đang trong quá trình",
          4: "Hoàn thành",
          5: "Từ chối",
          all: "Tất cả"
        },
        createSuccessTicket: "Tạo phiếu hỗ trợ thành công",
        ticketNotFound: "Không tìm thấy phiếu hỗ trợ",
        titleAndDateCannotBeBlank:"Tiêu đề và Ngày không được để trống.",
        validateDate:"Vui lòng chọn ngày hợp lệ (hôm nay hoặc muộn hơn)",
        titleIsRequired: "Tiêu đề là bắt buộc",
        ticketList:"Danh sách ticket",
        confirmDelete:"Xác nhận xóa",
        toDeleteThisTicket:"Bạn có chắc rằng bạn muốn xóa ticket này không?",
        delete:"Xóa",
        edit:"Sửa",
        saveChanges:"Lưu",
        ticketDetails:"Chi tiết ticket",
        ticketComments:"Bình luận",
        comment:"Bình luận",
        noDescription:"Không có bình luận",
        cancel:"Hủy",
        create:"Tạo",
        createTicketAttendance:"Tạo ticket điểm danh",
        createTicket:"Tạo ticket",
        createDate:"Ngày tạo",
        details:"Chi tiết",
        approve:"Chấp nhận",
        reject:"Từ chối",
        listAttendanceTicket:"Danh sách ticket điểm danh",
        iD:"Mã Ticket",
        creator	:"Người tạo",
        assignee	:"Người nhận",
        title	:"Tiêu đề",
        description	:"Nội dung",
        dateRequire :"Ngày yêu cầu",
        status	:"Trạng thái",
        actions:"Hành động",
        date:"Ngày",
        checkInTime:"Thời gian Check-In",
        checkOutTime:"Thời gian Check-Out",
        reason:"Lý do",
        needLoginFirst:"Cần đăng nhập trước!",
        checkPermissionToView:"Bạn không có thẩm quyền xem thông tin yêu cầu.",
        notFoundCommentTicket:"Không tìm thấy bình luận về yêu cầu.",
        updateTicketSuccessfully:"Cập nhật yêu cầu thành công!",
        descriptionEmpty:"Mô tả trống",
        commentSuccess:"Bình luận thành công",
        spamComment:"Bình luận bị đánh dấu là spam",
        inputEmpty:"Dữ liệu đầu vào trống",
        approveTicketSuccessfully:"Phê duyệt yêu cầu thành công",
        rejectTicketSuccessfully:"Từ chối vé thành công",
        dontHaveLabInfor:"Người dùng chưa có thông tin phòng lab",
        createSuccessTicketAttendance:"Tạo thành công phiếu điểm danh.",
        createFaileTicketAttendance:"Không thể tạo phiếu điểm danh! Vui lòng thử lại.",
        dontHaveMentor:"Người dùng không có mentor.",
        duplicateTicket:"Vé bị trùng lặp.",
        notFoundLabManager:"Không tìm thấy Quản lý phòng thí nghiệm.",
        createTicketFailse:"Tạo vé không thành công!",
        updateTicketFailse:"Sửa vé không thành công",
        success:"Thành công",
        failseAPI:"Lỗi khi tải ticket",
        notFoundTickets:"Không có ticket nào.",
        close:"Đóng",
      },
      settingLab:{
        timeRequired:"Thời gian yêu cầu",
        nothingChange:"Không có gì thay đổi",
        cancel:"Hủy",
        save:"Lưu",
        leaveRequestNum:"Số buổi nghỉ",
        noDayOffsAvailable:"Không có ngày nghỉ nào",
        settingDayOff:"Cài đặt ngày nghỉ",
        settingTimeLab:"Cài đặt thời gian phòng Lab",
        leaveRequestNumValidate:"Số lượng yêu cầu nghỉ phải lớn hơn 0.",
        theDayOffDateAlreadyExists:"Ngày nghỉ đã tồn tại.",
        CreatedSuccess:"Ngày nghỉ đã được tạo thành công!",
        CreateFailse:"Tạo ngày nghỉ thất bại!",
        noDayOffFound:"Không tìm thấy ngày nghỉ.",
        deleteDayOffSuccess:"Xóa ngày nghỉ thành công.",
        checkInTime:"Thời gian Check-in",
        checkOutTime:"Thời gian Check-out",
      },
      currentSemester: {
        name: "Kỳ hiện tại: {{name}}",
        period: "Bắt Đầu: {{startDate}} - Kết thúc: {{endDate}}",
      }
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
