import React, { useState } from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Slide, Snackbar } from '@mui/material';
import { useAuth } from '../../customs/authService';

const languages = [
  { name: 'EN', value: 'en' },
  { name: 'VI', value: 'vi' },
];

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

export default function LanguageSpeedDial() {
  const { t, i18n } = useTranslation();
  const {auth} = useAuth()
  const [snackBar, setSnackBar] = useState(false);

  return auth?.user && (
    <>
      <Snackbar
        open={snackBar}
        TransitionComponent={SlideTransition}
        message={t('langChange')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={1200}
      />
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{
          position: 'fixed',
          bottom: 16,
          top: 16,
          right: 16,
          zIndex: 1000, // Ensure it's above other content
        }}
        icon={<Language />}
      >
        {languages.map((lang) => (
          <SpeedDialAction
            key={lang.name}
            icon={lang.name}
            tooltipTitle={lang.name}
            onClick={() => {
              i18n.changeLanguage(lang.value);
              localStorage.setItem("lang", lang.value)
              setSnackBar(true);
              setTimeout(() => setSnackBar(false), 2000);
            }}
          />
        ))}
      </SpeedDial>
    </>
  );
}
