import React from "react";
import { useAuth } from "../../customs/authService";
import Home from "./Home";
import DashboardComponent from "./Dashboard";
import DashboardAdmin from "./DashboardAdmin";

const DashboardAll = () => {
  const { auth } = useAuth();

  const roles = auth.user.roles;
  return (
    <>
      {roles.includes("Intern") && <Home />}
      {roles.includes("Mentor") && (
        <DashboardComponent />
      )}
      {roles.includes("System Admin") && (
        <DashboardAdmin />
      )}
    </>
  );
};

export default DashboardAll;
