import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  Paper,
  TableBody,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiAuth from "../../customs/axiosCustom";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const DashboardAdmin = () => {
  const [attendancesSumary, setAttendancesSumary] = useState(0);
  const [internsCount, setInternsCount] = useState(null);
  const [internsError, setInternsError] = useState(null);
  const [interns, setInterns] = useState([]);
  const [showInternsList, setShowInternsList] = useState(false);
  const [keyResultsSummary, setKeyResultsSummary] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [chartType, setChartType] = useState("bar"); // State for chart type
  const { t } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSemesters = async () => {
      try {
        const response = await apiAuth.get("/Semester"); // Adjust this endpoint as needed
        const data = response.data || [];
        setSemesters(data);
        setSelectedSemester(data[0].semesterName);
      } catch (err) {
        toast.error(`Error fetching semesters: ${err.message}`);
      }
    };

    fetchSemesters();
  }, []);

  useEffect(() => {
    if (selectedSemester) {
      fetchTotalInterns(selectedSemester);
    }
  }, [selectedSemester]);

  const handleAddReportClick = (intern) => {
    navigate(`/add-report`, {
      state: { internData: intern },
    });
  };

  const fetchTotalInterns = async (semesterName) => {
    setShowInternsList(false);
    showLoading();
    try {
      const response = await apiAuth.get(
        `/DashBoard/summarywithinterns/${encodeURIComponent(semesterName)}`
      );
      const data = response.data;

      if (!data || data.count === 0) {
        setInternsCount(0);
        setInterns([]);
        setInternsError(t("reportV.errors1.409"));
        toast.info(t("reportV.errors1.409"));
      } else {
        setInternsCount(data.count);
        setInterns(data.interns);
        setInternsError(null);
        setShowInternsList(true);
      }
      setKeyResultsSummary(data.summary.keyResultsSummary || []);
      setAttendancesSumary(data.summary.attendancesSumary || []);
    } catch (err) {
      setInternsCount(null);
      setKeyResultsSummary(null);
      setAttendancesSumary(null);
      setInterns([]);
      switch (err.response.status) {
        case 400:
          toast.error(t("reportV.errors1.400"));
          break;
        case 404:
          toast.error(t("reportV.errors1.409"));
          break;
        default:
          toast.error(t("systemError"));
      }
    } finally {
      hideLoading();
    }
  };

  const toggleChartType = () => {
    setChartType((prevType) => (prevType === "bar" ? "line" : "bar"));
  };

  const keyResultsChartData = {
    labels: (keyResultsSummary || []).map(
      (item) => `${item.month}/${item.year}`
    ),
    datasets: [
      {
        label: t("report.notStarted"),
        data: (keyResultsSummary || []).map(
          (item) => item.totalNotStarted || 0
        ),
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        borderColor: "red",
        borderWidth: 1,
      },
      {
        label: t("report.inProgress"),
        data: (keyResultsSummary || []).map(
          (item) => item.totalInProgress || 0
        ),
        backgroundColor: "rgba(255, 165, 0, 0.5)",
        borderColor: "orange",
        borderWidth: 1,
      },
      {
        label: t("report.completed"),
        data: (keyResultsSummary || []).map((item) => item.totalCompleted || 0),
        backgroundColor: "rgba(0, 255, 0, 0.5)",
        borderColor: "green",
        borderWidth: 1,
      },
      {
        label: t("report.failed"),
        data: (keyResultsSummary || []).map((item) => item.totalFailed || 0),
        backgroundColor: "rgba(0, 0, 255, 0.5)",
        borderColor: "blue",
        borderWidth: 1,
      },
    ],
  };

  const attendanceChartData = {
    labels: (attendancesSumary || []).map(
      (item) => `${item.month}/${item.year}`
    ),
    datasets: [
      {
        label: t("report.attended"),
        data: (attendancesSumary || []).map(
          (item) => item.totalAttendanced || 0
        ),
        backgroundColor: "rgba(0, 255, 0, 0.5)",
        borderColor: "green",
        borderWidth: 1,
      },
      {
        label: t("report.absent"),
        data: (attendancesSumary || []).map((item) => item.totalAbsent || 0),
        backgroundColor: "rgba(255, 0, 0, 0.5)",
        borderColor: "red",
        borderWidth: 1,
      },
      {
        label: t("report.lateIn"),
        data: (attendancesSumary || []).map((item) => item.totalLateIn || 0),
        backgroundColor: "rgba(255, 165, 0, 0.5)",
        borderColor: "orange",
        borderWidth: 1,
      },
      {
        label: t("report.soonOut"),
        data: (attendancesSumary || []).map((item) => item.totalSoonOut || 0),
        backgroundColor: "rgba(0, 0, 255, 0.5)",
        borderColor: "blue",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t("reportV.dashBoard")}
      </Typography>
      
      <Card style={{ width: "100%", margin: "auto" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem",
            }}
          >
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  sx={{ minWidth: 200, minHeight: 30 }}
                >
                  <InputLabel id="semester-select-label">
                    {t("reportV.semesterName")}
                  </InputLabel>
                  <Select
                    labelId="semester-select-label"
                    value={selectedSemester}
                    onChange={(e) => {
                      const semesterName = e.target.value;
                      setSelectedSemester(semesterName);
                    }}
                    label={t("reportV.semesterName")}
                    sx={{ fontSize: "1rem", padding: "2px" }}
                  >
                    {semesters.map((semester) => (
                      <MenuItem
                        key={semester.semesterId}
                        value={semester.semesterName}
                      >
                        {semester.semesterName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  onClick={toggleChartType}
                  variant="contained"
                  color="secondary"
                >
                  {chartType === "bar"
                    ? t("report.toggleChartType")
                    : t("report.toggleChartType")}
                </Button>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  padding: 2,
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("report.keyResultsOverview")}
                </Typography>
                {chartType === "bar" ? (
                  <Bar data={keyResultsChartData} options={options} />
                ) : (
                  <Line data={keyResultsChartData} options={options} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  padding: 2,
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {t("report.attendanceOverview")}
                </Typography>
                {chartType === "bar" ? (
                  <Bar data={attendanceChartData} options={options} />
                ) : (
                  <Line data={attendanceChartData} options={options} />
                )}
              </Box>
            </Grid>
          </Grid>

          {showInternsList && (
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>
                {t("reportV.internsList")} ({internsCount || 0})
              </Typography>
              {internsError ? (
                <Typography color="error">{internsError}</Typography>
              ) : (
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: 400, overflowY: "auto" }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("reportV.nameI")}</TableCell>
                        <TableCell>{t("reportV.rollName")}</TableCell>
                        <TableCell>{t("reportV.lName")}</TableCell>
                        <TableCell>{t("reportV.ac")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {interns.map((intern) => (
                        <TableRow key={intern.id}>
                          <TableCell>{intern.nameIntern}</TableCell>
                          <TableCell>{intern.rollName}</TableCell>
                          <TableCell>{intern.nameLab}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleAddReportClick(intern)}
                            >
                              {t("report.addReport")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default DashboardAdmin;
