import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement } from 'chart.js';
import { OKRContext } from '../../context/OKRContext'; // Adjust the import to your actual context file
import { useAuth } from "../../customs/authService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

const DashboardView = () => {
  const { objectives = [], keyResults = [], progressPerformances = [] } = useContext(OKRContext);
  const { t } = useAuth();

  useEffect(() => {
    console.log('Objectives:', objectives);
    console.log('Key Results:', keyResults);
    console.log('Progress Performances:', progressPerformances);
  }, [objectives, keyResults, progressPerformances]);

  // Helper function to generate random colors
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Prepare data for Key Results Progress chart
  const keyResultsColors = keyResults.map(() => getRandomColor());
  const keyResultsData = {
    labels: keyResults.map((kr) => kr.title),
    datasets: [
      {
        label: t("okr.ch_kr"),
        data: keyResults.map((kr) => kr.currentValue), // Ensure this matches your data structure
        backgroundColor: keyResultsColors,
        borderColor: keyResultsColors,
        borderWidth: 1,
      },
    ],
  };

  // Prepare data for Objective Progress Over Time chart
  const objectivesData = {
    labels: objectives.map((obj) => obj.title),
    datasets: [
      {
        label: t("okr.ch_o"),
        data: objectives.map((obj) => {
          const keyResultsForObjective = keyResults.filter((kr) => kr.objectiveId === obj.objectiveId);
          const progress = keyResultsForObjective.reduce((sum, kr) => sum + (kr.currentValue / kr.targetValue) * 100, 0) / keyResultsForObjective.length;
          return progress || 0;
        }),
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1,
      },
    ],
  };

  // Prepare data for Priority Distribution chart
  const priorityCounts = keyResults.reduce((acc, kr) => {
    acc[kr.priority] = (acc[kr.priority] || 0) + 1;
    return acc;
  }, {});

  const priorityLabels = Object.keys(priorityCounts);
  const priorityData = Object.values(priorityCounts);
  const priorityColors = priorityLabels.map(() => getRandomColor());

  const priorityDistributionData = {
    labels: priorityLabels,
    datasets: [
      {
        label: t("okr.ch_pd"),
        data: priorityData,
        backgroundColor: priorityColors,
        borderColor: priorityColors,
        borderWidth: 1,
      },
    ],
  };

  // Prepare data for Progress Performance chart
  const progressPerformanceData = {
    labels: progressPerformances.map((pp) => new Date(pp.reportDate).toLocaleDateString()), // Ensure proper date formatting
    datasets: [
      {
        label: t("okr.ch_c_t"),
        data: progressPerformances.map((pp) => ({
          x: new Date(pp.reportDate).toLocaleDateString(),
          y: pp.currentValue,
          target: pp.targetValue,
        })),
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        parsing: {
          xAxisKey: 'x',
          yAxisKey: 'y',
        },
      },
    ],
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {t("okr.dashboard")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            {t("okr.ch_kr")}
          </Typography>
          <Bar
            data={keyResultsData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`,
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  max: 100, // Set the maximum value of the y-axis to 100%
                  ticks: {
                    callback: (value) => `${value}%`, // Add % symbol to the ticks
                  },
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            {t("okr.ch_o")}
          </Typography>
          <Line
            data={objectivesData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`,
                  },
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: t("okr.ch_o_1"),
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: t("okr.ch_o_2"),
                  },
                  beginAtZero: true,
                  max: 100, // Set the maximum value of the y-axis to 100%
                  ticks: {
                    callback: (value) => `${value}%`, // Add % symbol to the ticks
                  },
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            {t("okr.ch_pd")}
          </Typography>
          <Bar
            data={priorityDistributionData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw} Key Results`,
                  },
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom>
            {t("okr.ch_pp")}
          </Typography>
          <Line
            data={progressPerformanceData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'top',
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: Current Value: ${tooltipItem.raw.y}, Target Value: ${tooltipItem.raw.target}`,
                  },
                },
              },
              scales: {
                x: {
                  title: {
                    display: true,
                    text: t("okr.ch_pp_1"),
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: t("okr.ch_pp_2"),
                  },
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardView;
