// src/context/CandidatesContext.js
import React, { createContext, useState, useContext } from "react";
import apiAuth from "../customs/axiosCustom"; // Import your custom axios instance
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

const CandidatesContext = createContext();

export const CandidatesProvider = ({ children }) => {
  const { auth, t } = useAuth();
  const [candidates, setCandidates] = useState([]);
  const [unassignedCandidates, setUnassignedCandidates] = useState([]);
  const [candidateCVs, setCandidateCVs] = useState(null); // Store CVs separately
  const [error, setError] = useState(null);
  const [mentorId, setMentorId] = useState(null); // Assuming mentorId is available in your state
  const [mentors, setMentors] = useState([]); // Assuming mentorId is available in your state

  const fetchCandidates = async () => {
    try {
      const response = await apiAuth.get("/Candidate/GetAssignCandidate"); // Adjust the endpoint if needed
      setCandidates(response.data.importInterns);
      if (response.data.importInterns.length > 0) {
        setMentorId(response.data.importInterns[0].mentorID); // Set mentorId from the first candidate
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching candidates.");
    }
  };

  const fetchMentors = async () => {
    try {
      const response = await apiAuth.get("/Candidate/GetMentors");
      setMentors(response.data.mentors);
    } catch (err) {
      setError(err.message || "An error occurred while fetching candidates.");
    }
  };

  const fetchUnassignedCandidates = async () => {
    try {
      if (auth.user.roles.includes("Lab Manager")) {
        const response = await apiAuth.get("/Candidate/GetUnAssignCandidate"); // Adjust the endpoint if needed
        setUnassignedCandidates(response.data.importInterns);
      }
    } catch (err) {
      setError(err.message || "An error occurred while fetching candidates.");
    }
  };

  const fetchCandidateCV = async (candidateId) => {
    try {
      const candidate = candidates.find((c) => c.id === candidateId);
      const unassignCandidate = unassignedCandidates.find(
        (c) => c.id === candidateId
      );
      if (!candidate && !unassignCandidate) return;
      const response = await apiAuth.get(`/Candidate/GetCV/${candidateId}`);
      const { base64PDF } = response.data;
      if (base64PDF) {
        const cvSrc = base64PDF;
        setCandidateCVs(cvSrc);
        return;
      }
      toast.warning(t(`candidate.cvNotFound`));
    } catch (err) {
      setError(
        err.message || "An error occurred while fetching the candidate CV."
      );
    }
  };

  const assignImportIntern = async (candidateId, mentorId) => {
    try {
      const candidate = unassignedCandidates.find((c) => c.id === candidateId);
      if (!candidate) return;
      const response = await apiAuth.post(
        `/Candidate/AssignToMentor/${mentorId}`,
        candidateId,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const { message } = response.data;
      toast.success(t(`candidate.${message}`));
      const updatedCandidate = { ...candidate, mentorId: mentorId };
      const updatedCandidates =
        auth.user.jti === mentorId ? [...candidates, updatedCandidate] : [];
      const updatedUnassign = unassignedCandidates.filter(
        (c) => c.id !== updatedCandidate.id
      );
      setCandidates(updatedCandidates);
      setUnassignedCandidates(updatedUnassign);
    } catch (err) {
      setError(err.message || "An error occurred");
    }
  };

  const approveOrRejectInterns = async (internApprovals) => {
    try {
      const response = await apiAuth.post(
        `/Candidate/ApproveOrRejectInterns`,
        internApprovals
      );
      const { data } = response;

      if (auth.user.roles.includes("Lab Manager")) {
        const rejectedInternIds = internApprovals
          .filter((a) => !a.Approved)
          .map((a) => a.InternId);
        const updateUnassign = [
          ...unassignedCandidates,
          candidates.filter((c) => rejectedInternIds.includes(c.id))[0],
        ];
        setUnassignedCandidates(updateUnassign);
      }
      // Remove approved interns from the local state
      fetchCandidates();
      toast.success(t(`candidate.${data.message}`));
    } catch (err) {
      toast.error(t(`candidate.${err.response.message}`));
    }
  };
  return (
    <CandidatesContext.Provider
      value={{
        candidates,
        mentorId,
        candidateCVs,
        error,
        setCandidates,
        fetchCandidateCV,
        approveOrRejectInterns,
        fetchCandidates,
        fetchUnassignedCandidates,
        assignImportIntern,
        fetchMentors,
        unassignedCandidates,
        mentors,
      }}
    >
      {children}
    </CandidatesContext.Provider>
  );
};

export const useCandidates = () => useContext(CandidatesContext);
