import React, { useState } from 'react';
import { TextField, Button, Paper } from '@mui/material';
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { toast } from 'react-toastify';

const CreateTicketPage = ({ onCreateSuccess }) => {
    const { t, auth } = useAuth();
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [description, setDescription] = useState('');

    const handleCreateTicket = async () => {
        if (validateFields()) {
            try {
                const response = await apiAuth.post('/Tickets/create-ticket', {
                    title,
                    description
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                });

                if (response.status === 200) {
                    const successMessage = response.data || 'Ticket created successfully!';
                    toast.success(t(successMessage));
                    // Reset form fields
                    setTitle('');
                    setDescription('');
                    onCreateSuccess(); // Gọi hàm onCreateSuccess sau khi tạo ticket thành công
                } else {
                    const errorMessage = response.data || 'Failed to create ticket';
                    toast.error(t(errorMessage));
                }
            } catch (error) {
                console.error('Error creating ticket:', error);
                const errorMessage = error.response?.data || 'An unexpected error occurred';
                toast.error(t(errorMessage));
            }
        }
    };

    const validateFields = () => {
        let isValid = true;

        // Validate title
        if (title.trim() === '') {
            setTitleError(t("ticket.titleIsRequired"));
            isValid = false;
        } else {
            setTitleError('');
        }

        return isValid;
    };

    return (
        <Paper style={{ padding: 16 }}>
            <h2>{t("ticket.createTicket")}</h2>
            <form noValidate autoComplete="off">
                <TextField
                    fullWidth
                    label={t("ticket.title")}
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    error={!!titleError}
                    helperText={titleError}
                    style={{ marginBottom: 16 }}
                />
                <TextField
                    fullWidth
                    label={t("ticket.description")}
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ marginBottom: 16 }}
                />
                <Button variant="contained" color="primary" onClick={handleCreateTicket}>
                    {t("ticket.createTicket")}
                </Button>
            </form>
        </Paper>
    );
};

export default CreateTicketPage;
