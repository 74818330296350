import React, { useRef, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import * as faceapi from "@vladmandic/face-api";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  padding: 3,
  bgcolor: "background.paper",
};

const AttendanceModal = ({
  open,
  isCheckIn,
  handleClose,
  handleSetNotify,
  faceVector,
  submitUrl,
  handleCheckIn,
  handleCheckInSubmitTime,
  handleCheckOutSubmitTime,
  handleFaceVector,
}) => {
  const { t } = useAuth();
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [faceVectorSubmit, setfaceVectorSubmit] = useState("1");
  const [otp, setOtp] = useState("");
  const [mode, setMode] = useState("face");
  const [hasCamera, setHasCamera] = useState(true);

  const loadModels = async () => {
    await faceapi.nets.tinyFaceDetector.loadFromUri("/model");
    await faceapi.nets.faceLandmark68Net.loadFromUri("/model");
    await faceapi.nets.faceRecognitionNet.loadFromUri("/model");
  };

  useEffect(() => {
    loadModels()
  }, [])

  const stopVideo = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  function cosineSimilarity(vecA, vecB) {
    const dotProduct = vecA.reduce((sum, val, i) => sum + val * vecB[i], 0);
    const magnitudeA = Math.sqrt(vecA.reduce((sum, val) => sum + val * val, 0));
    const magnitudeB = Math.sqrt(vecB.reduce((sum, val) => sum + val * val, 0));
    return dotProduct / (magnitudeA * magnitudeB);
  }

  function areFaceVectorsSimilar(vector1, vector2, threshold = 0.8) {
    const distance = cosineSimilarity(vector1, vector2);
    return distance > threshold;
  }

  const checkCamera = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setHasCamera(videoDevices.length > 0);
      })
      .catch((err) => {
        console.error("Error checking devices:", err);
      });
  };

  const handleVideoPlay = async () => {
    const options = new faceapi.TinyFaceDetectorOptions();
    const detectFace = async () => {
      if (videoRef.current) {
        const result = await faceapi
          .detectSingleFace(videoRef.current, options)
          .withFaceLandmarks()
          .withFaceDescriptor();
        setIsFaceDetected(!!result);
        if (result) {
          const faceDescriptors = result.descriptor;
          setfaceVectorSubmit(faceDescriptors);

          if (faceVector !== null) {
            const faceVectorUser = new Float32Array(128);
            for (let i = 0; i < 128; i++) {
              faceVectorUser[i] =
                faceVector[i] !== undefined
                  ? faceVector[i]
                  : 0;
            }
            const isEqual = areFaceVectorsSimilar(
              faceVectorUser,
              faceDescriptors
            );
            if (isEqual) {
              stopVideo();
              handleSubmit(faceDescriptors);
            }
          }
        }
      }
      requestAnimationFrame(detectFace);
    };
    detectFace();
  };

  useEffect(() => {
    checkCamera();

    const startVideo = () => {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          streamRef.current = stream;
          videoRef.current.srcObject = stream;
        })
        .catch((err) => {
          console.error(err);
          setHasCamera(false);
        });
    };

    if (mode === "face" && open && hasCamera) {
      loadModels().then(startVideo);
    } else {
      stopVideo();
    }

    return () => {
      stopVideo();
    };
  }, [mode, open, hasCamera]);

  useEffect(() => {
    if (mode !== "face" || !open) {
      stopVideo();
    }
  }, [mode, open]);

  const handleCheckInOut = () => {
    if (isCheckIn) {
      handleCheckOutSubmitTime("checkOut");
      handleCheckIn(false);
    } else {
      handleCheckInSubmitTime("checkIn");
      handleCheckIn(true);
    }
    handleSetNotify(false);
    handleClose();
  };

  const handleOtp = () => {
    apiAuth
      .post("/attendance/sent-otp")
      .then((res) => toast.success(t(res?.data?.message)))
      .catch((error) => {
        toast.error(t(error?.response?.data?.message));
      });
  };

  const handleSubmitFace = (faceDescriptors) => {
    if (faceVector === null) {
      handleFaceVector(faceDescriptors)
    }
    handleSubmit(faceDescriptors)
  }

  const handleSubmit = (faceDescriptors) => {
    if (mode === "face") {
      const faceVectorNow = {...faceDescriptors}
      apiAuth
        .post(
          submitUrl,
          { faceVector: JSON.stringify(faceVectorNow) },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          handleCheckInOut();
          toast.success(t(res.data?.message));
        })
        .catch((error) => toast.error(t(error?.response?.data?.message)));
    } else if (mode === "otp" && otp) {
      apiAuth
        .post(
          submitUrl,
          { otp: otp },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          handleCheckInOut();
          toast.success(t(res.data?.message));
        })
        .catch((error) => toast.error(t(error?.response?.data?.message)));
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          {isCheckIn ? t("attendance.checkIn") : t("attendance.checkOut")}
        </Typography>
        <RadioGroup row value={mode} onChange={(e) => setMode(e.target.value)}>
          <FormControlLabel
            value="face"
            control={<Radio />}
            label={t("faceScan")}
          />
          <FormControlLabel value="otp" control={<Radio />} label={t("otp")} />
        </RadioGroup>
        {mode === "face" ? (
          <video
            ref={videoRef}
            onPlay={handleVideoPlay}
            autoPlay
            muted
            style={{ width: "100%" }}
          />
        ) : (
          <>
            <TextField
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Nhập mã OTP"
              fullWidth
              margin="normal"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />

            <Link onClick={() => handleOtp()}>{t("getOtp")}</Link>
          </>
        )}
        {faceVector == null ? (
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleSubmitFace(faceVectorSubmit)
              }
              disabled={mode === "face" && !isFaceDetected}
            >
              {t('approve')}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

export default AttendanceModal;
