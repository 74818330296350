import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";

const SemesterList = () => {
  const {auth} = useAuth()
  const [semesters, setSemesters] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [internsLoading, setInternsLoading] = useState(false);
  const [interns, setInterns] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const [lab, setLab] = useState(null);
  const navigate = useNavigate();
  const { t } = useAuth();

  useEffect(() => {
    const fetchSemesters = async () => {
      try {
        const response = await apiAuth.get("/Semester");
        setSemesters(response.data);
        setError(null);
      } catch (err) {
        toast.error("Error fetching semesters.");
      }
    };

    const fetchReports = async () => {
      try {
        const response = await apiAuth.get("/Report");
        setReports(response.data);
        setSearchResults(response.data); // Set initial search results to all reports
        setError(null);
      } catch (err) {
        toast.error("Error fetching reports.");
      }
    };

    const fetchLab = async () => {
      try {
        const response = await apiAuth.get(`/Semester/lab/${auth.user.labId}`);
        setLab(response.data);
      } catch (err) {
        toast.error("Error fetching reports.");
      }
    };

    try {
      showLoading();
      fetchSemesters();
      fetchReports();
      fetchLab();
    } catch (err) {
      toast.error("Error fetching");
    } finally {
      hideLoading();
    }
  }, []);

  const handleViewSemesterClick = async (semester) => {
    setInternsLoading(true);
    setError(null);

    try {
      const response = await apiAuth.get(
        `/Semester/${semester.semesterId}/interns`
      );

      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setInterns(response.data);
        } else {
          toast.info(t("reportV.errors1.409"));
          setInterns([]);
        }
      } else {
        toast.error(t("reportV.sUnex"));
      }
      setSelectedSemester(semester); // Set selectedSemester to the current semesterId
      setOpen(true);
    } catch (err) {
      if (err.response) {
        const errorMessage = err.response.data.message;

        switch (err.response.status) {
          case 400:
            toast.error(t("reportV.errors1.400"));
            break;
          case 409:
            toast.error(t("reportV.errors1.409"));
            break;
          case 500:
            toast.error(
              errorMessage || "Server error. Please try again later."
            );
            break;
          default:
            toast.error(errorMessage);
            break;
        }
      } else {
        toast.error(
          "An unexpected error occurred. Please check your connection."
        );
      }
    } finally {
      setInternsLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setInterns([]);
  };

  const handleAddReportClick = (intern) => {
    toast.info("Navigating to Add Report page");
    navigate(`/add-report`, {
      state: { internData: intern },
    });
  };

  const handleExportExcel = async () => {
    setLoading(true);
    try {
      const response = await apiAuth.get(`/Report/export/excel/${lab?.id}/${selectedSemester.semesterId}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let filename = `Final-Report-${lab?.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.message || t("systemError"));
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExportExcelMiddle = async () => {
    setLoading(true); // Set loading state to true when starting export
    try {
      const response = await apiAuth.get(
        `/Report/exportMiddle/excel/${lab?.id}/${selectedSemester.semesterId}`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      let filename = `Middle-Report-${lab?.labName}-${selectedSemester.semesterName}.xlsx`; // Tên mặc định
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t("reportV.exportSuccess"));
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 404) {
          toast.warning(data.Message || t("reportV.excelNotFound"));
        } else if (status === 400) {
          toast.error(data.Message || t("reportV.sExcel400"));
        } else if (status === 500) {
          toast.error(data.Message || "Server error. Please try again later.");
        } else {
          toast.error("An unexpected error occurred during export.");
        }
      } else {
        toast.error("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false); // Set loading state to false after export is complete
    }
  };

  const handleDeleteReport = (reportId) => {
    if (window.confirm(t("reportV.sDelete"))) {
      apiAuth
        .delete(`/Report/${reportId}`)
        .then(() => {
          // Update local state to remove the deleted report
          setReports(reports.filter((report) => report.reportId !== reportId));
          setSearchResults(
            searchResults.filter((report) => report.reportId !== reportId)
          );
          toast.success(t("reportV.sDeleteSuc"));
        })
        .catch((error) => {
          if (error.response) {
            // Server responded with an error
            const status = error.response.status;
            const message =
              error.response.data.message ||
              "Error deleting report. Please try again later.";

            switch (status) {
              case 400:
                toast.error(`Bad Request: ${message}`);
                break;
              case 404:
                toast.error(`Not Found: ${message}`);
                break;
              case 500:
                toast.error(`${message}`);
                break;
              default:
                toast.error(`${message}`);
                break;
            }
          } else if (error.request) {
            // Request was made but no response received
            toast.error(
              "No response from server. Please check your network connection."
            );
          } else {
            // Something else went wrong
            toast.error("Error deleting report. Please try again later.");
          }
        });
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async () => {
    setSearchError(null);
    if (!searchQuery.trim()) {
      setSearchError();
      setSearchResults(reports); // Display all reports if search query is empty
      return;
    }
    setLoading(true);
    try {
      const response = await apiAuth.get(`/Report/rollname/${searchQuery}`);
      if (response.status === 404) {
        setSearchError(t("reportV.sSearch"));
        setSearchResults([]);
      } else {
        setSearchResults(response.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setSearchError(t("reportV.sSearch"));
        setSearchResults([]);
      } else {
        toast.error("Error fetching report.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {t("reportV.listOfSemesters")}
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("reportV.semesterName")}</TableCell>
              <TableCell align="center">{t("reportV.sd")}</TableCell>
              <TableCell align="center">{t("reportV.ed")}</TableCell>
              <TableCell align="center">{t("reportV.ac")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {semesters.map((semester) => (
              <TableRow key={semester.semesterId}>
                <TableCell align="center">{semester.semesterName}</TableCell>
                <TableCell align="center">
                  {new Date(semester.startDate).toLocaleDateString("en-GB")}
                </TableCell>
                <TableCell align="center">
                  {new Date(semester.endDate).toLocaleDateString("en-GB")}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewSemesterClick(semester)}
                  >
                    {t("reportV.viewIntern")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>{t("reportV.internsforSemester")}</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("reportV.nameI")}</TableCell>
                  <TableCell>{t("reportV.email")}</TableCell>
                  <TableCell>{t("reportV.rollName")}</TableCell>
                  <TableCell>{t("reportV.ac")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interns.length > 0 ? (
                  interns.map((intern) => (
                    <TableRow key={intern.id}>
                      <TableCell>{intern.nameIntern}</TableCell>
                      <TableCell>
                        {intern.email}
                      </TableCell>
                      <TableCell>{intern.rollName}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleAddReportClick(intern)}
                          style={{ marginLeft: "10px" }}
                        >
                          {t("report.addReport")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Typography>{t("reportV.noi")}</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("reportV.close")}
          </Button>
          <Button
            onClick={() => handleExportExcelMiddle()}
            color="primary"
          >
            {t("reportV.exporttoExcel1")}
          </Button>
          <Button
            onClick={() => handleExportExcel()}
            color="primary"
          >
            {t("reportV.exporttoExcel")}
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={2} display="flex" alignItems="center" marginY={2}>
        <TextField
          label={t("reportV.searchbyRollName")}
          variant="outlined"
          value={searchQuery}
          fullWidth
          onChange={handleSearchChange}
          style={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
          size="medium"
          sx={{width: 150}}
        >
          {t("reportV.search")}
        </Button>
      </Box>
      {searchError && <Typography color="error">{searchError}</Typography>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width: 200}}>{t("reportV.createdDate")}</TableCell>
              <TableCell>{t("reportV.sID")}</TableCell>
              <TableCell>{t("reportV.rollName")}</TableCell>
              <TableCell>{t("reportV.nameI")}</TableCell>
              <TableCell>{t("reportV.lName")}</TableCell>
              <TableCell>{t("reportV.mName")}</TableCell>
              <TableCell>{t("reportV.allowance")}</TableCell>
              <TableCell>{t("report.commentOfCompany")}</TableCell>
              <TableCell>{t("report.majorSkill")}</TableCell>
              <TableCell>{t("report.softSkill")}</TableCell>
              <TableCell>{t("report.attitude")}</TableCell>
              <TableCell>{t("reportV.finalResult")}</TableCell>
              <TableCell>{t("reportV.ac")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchResults.map((report) => (
              <TableRow key={report.reportId}>
                <TableCell>
                  {new Date(report.createdDate).toLocaleString("en-US", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false, // Use 24-hour time format
                  })}
                </TableCell>
                <TableCell>{report.staffId}</TableCell>
                <TableCell>{report.rollName}</TableCell>
                <TableCell>{report.nameIntern}</TableCell>
                <TableCell>{report.nameLab}</TableCell>
                <TableCell>{report.nameMentor}</TableCell>
                <TableCell>{report.allowance}</TableCell>
                <TableCell>{report.comOfCompany}</TableCell>
                <TableCell>{report.majorSkill}</TableCell>
                <TableCell>{report.softSkill}</TableCell>
                <TableCell>{report.attitude}</TableCell>
                <TableCell>{report.finalResult}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteReport(report.reportId)}
                  >
                    {t("reportV.delete")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SemesterList;
