import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';
import { OKRContext } from '../../context/OKRContext';
import Gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { useAuth } from "../../customs/authService";

const TimelineView = () => {
  const { objectives, keyResults } = useContext(OKRContext);
  const { t } = useAuth();

  useEffect(() => {
    // Inject custom CSS to disable pointer events
    const style = document.createElement('style');
    style.innerHTML = `
      #gantt_here {
        width: 100%;
        height: 500px;
        overflow: auto;
      }
      .gantt_add, .gantt_grid_head_add {
        display: none !important; /* Ensure the add button is hidden */
      }
      .gantt_grid_head_cell {
        white-space: normal !important;
        overflow: visible !important;
        text-overflow: unset !important;
        height: auto !important;
        line-height: normal !important;
      }
    `;
    document.head.appendChild(style);

    // Initialize Gantt when objectives, keyResults, or language changes
    const taskData = prepareTaskData(objectives, keyResults);
    initializeGantt(taskData);

    // Cleanup function to clear Gantt data when the component unmounts
    return () => {
      Gantt.clearAll();
    };
  }, [objectives, t]); // Add 't' as a dependency

  const prepareTaskData = (objectives, keyResults) => {
    const taskData = objectives.map(objective => {
      const objectiveTask = {
        id: `objective-${objective.objectiveId}`,
        text: objective.title,
        start_date: formatDate(objective.startDate),
        duration: calculateDuration(objective.startDate, objective.endDate),
        progress: (objective.progress || 0) / 100,
        open: true, // Keeps the task expanded to show child tasks (key results)
        color: getStatusColor(objective.status), // Set color based on status
      };

      // const keyResultsData = keyResults
      //   .filter(kr => kr.objectiveId === objective.objectiveId)
      //   .map(kr => ({
      //     id: `keyResult-${kr.keyResultId}`,
      //     text: kr.title + getPriorityText(kr.priority), // Display priority as part of the text
      //     start_date: formatDate(kr.startDate),
      //     duration: calculateDuration(kr.startDate, kr.endDate),
      //     progress: (kr.progress || 0) / 100,
      //     parent: `objective-${objective.objectiveId}`, // Link the key result to its parent objective
      //     color: getStatusColor(kr.status), // Set color based on status
      //   }));

      // return [objectiveTask, ...keyResultsData];
      return objectiveTask
    });

    return taskData.flat();
  };

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const duration = (end - start) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
    return Math.round(duration);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const formattedDate = new Date(date);
    return isNaN(formattedDate) 
      ? null 
      : formattedDate.toLocaleString('en-GB', { // Adjust this format according to your region
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }).replace(',', '');
  };

  const getStatusColor = (status) => {
    // Define status colors (customize as needed)
    switch (status) {
      case 1:
        return '#FF0000'; // Red for critical
      case 2:
        return '#FFA500'; // Orange for warning
      case 3:
        return '#008000'; // Green for success
      case 4:
        return '#0000FF'; // Blue for other statuses
      default:
        return '#808080'; // Gray for unknown status
    }
  };

  const getPriorityText = (priority) => {
    switch (priority) {
      case 'High':
        return ` - ${t("okr.filter_2")}`;
      case 'Medium':
        return ` - ${t("okr.filter_3")}`;
      case 'Low':
        return ` - ${t("okr.filter_4")}`;
      default:
        return '';
    }
  };

  const initializeGantt = (taskData) => {
    // Set Gantt to read-only mode
    Gantt.config.readonly = true;

    // Customize the column headers
    Gantt.config.columns = [
      { name: "text", label: t("okr.title"), width: "*", tree: true },
      { name: "start_date", label: t("okr.start_Date"), align: "center" },
      { name: "duration", label: t("okr.tl_Duration"), align: "center" },
    ];

    // Ensure the chart is scrollable
    Gantt.config.scrollable = true;
    Gantt.config.autofit = true;

    // Hide the "+" button for adding tasks
    Gantt.config.show_task_buttons = false;

    // Disable the "Add Task" button in the grid
    Gantt.attachEvent("onGanttReady", function(){
      document.querySelectorAll('.gantt_add').forEach(button => {
        button.style.display = 'none';
      });
    });
    
    // Disable double-click on tasks and the timeline
    Gantt.attachEvent("onTaskDblClick", function(id, e) {
      return false;
    });
    Gantt.attachEvent("onEmptyClick", function(e) {
      return false;
    });

    Gantt.init("gantt_here");
    Gantt.clearAll(); // Clear any existing data
    Gantt.parse({ data: taskData }); // Load the task data into the Gantt chart
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {t("okr.timeline")}
      </Typography>
      <div id="gantt_here" style={{ width: '100%', height: '500px' }}></div>
    </div>
  );
};

export default TimelineView;
