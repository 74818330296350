import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import apiAuth from "../../customs/axiosCustom";  // Điều chỉnh đường dẫn cho đúng
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from "../../customs/authService";

const CreateTicketAttendance = ({ show, onClose }) => {
  const { t, auth } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateTicket = async () => {
    // Kiểm tra xem các trường có rỗng không
    if (!title || !date) {
      setError(t("ticket.titleAndDateCannotBeBlank"));
      return;
    }

    // Kiểm tra xem ngày có phải là ngày hợp lệ không (ngày hiện tại trở đi)
    const selectedDate = new Date(date);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Đặt thời gian của ngày hiện tại thành 00:00:00 để so sánh chính xác

    if (selectedDate < today) {
      setError(t("ticket.validateDate"));
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await apiAuth.post('/Tickets/createTicketsAttendance', {
        title,
        description,
        date,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        toast.success(t(response.data));
        onClose();
      } else {
        if (response.data && response.data.message) {
          setError(t(response.data.message));
        } else {
          setError('Error creating ticket.');
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(t(err.response.data.message));
      } else {
        setError('Error creating ticket.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Đặt ngày hiện tại làm giá trị tối thiểu
  const today = new Date().toISOString().split('T')[0];

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box 
        sx={{
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          {t("ticket.createTicketAttendance")}
        </Typography>
        <TextField
          label={t("ticket.title")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          margin="normal"
          required // Thêm thuộc tính required để nhắc nhở người dùng
        />
        <TextField
          label={t("ticket.description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          margin="normal"
          multiline
          rows={4}
        />
        <TextField
          label={t("ticket.date")}
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          inputProps={{ min: today }} // Thêm thuộc tính min để cho phép chọn ngày hôm nay trở đi
          required // Thêm thuộc tính required để nhắc nhở người dùng
        />
        {error && <Typography color="error">{error}</Typography>}
        <Box mt={2}>
          <Button onClick={handleCreateTicket} variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : t("ticket.create")}
          </Button>
          <Button onClick={onClose} variant="contained" color="primary" sx={{ ml: 2 }}>
          {t("ticket.cancel")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateTicketAttendance;
