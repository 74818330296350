import React, { useState, useEffect, useContext } from "react";
import NavBar from "../Okr/NavBar";
import TimelineView from "../Okr/TimelineView";
import DashboardView from "../Okr/DashboardView";
import MainTableView from "../Okr/MainTableView";
import OKRLogDialog from "../Okr/OKRLogDialog";
import TeamManagementDialog from "../Okr/TeamManagementDialog";
import { Box } from "@mui/material";
import { OKRContext } from "../../context/OKRContext";
import { useAuth } from "../../customs/authService";

const OKRPage = () => {
  const {auth} = useAuth();
  const [currentView, setCurrentView] = useState("objectives");
  const [openDialog, setOpenDialog] = useState(null);
  const { teamId, setTeamId, teams, fetchTeamId } = useContext(OKRContext);

  useEffect(() => {
    if (auth.user) {
      fetchTeamId();
    }
  }, [auth.user]);

  const renderView = () => {
    switch (currentView) {
      case "timeline":
        return <TimelineView />;
      case "chart":
        return <DashboardView />;
      case "maintable":
        return <MainTableView />;
      default:
        return <MainTableView />;
    }
  };

  const handleIconClick = (dialog) => {
    setOpenDialog(dialog);
  };

  const handleCloseDialog = () => {
    setOpenDialog(null);
  };

  const handleTeamChange = (event) => {
    setTeamId(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavBar
        currentView={currentView}
        onViewChange={setCurrentView}
        onIconClick={handleIconClick}
        teamId={teamId}
        onTeamChange={handleTeamChange}
        teams={teams}
      />
      <Box sx={{ flexGrow: 1, padding: "20px", width: "100%" }}>
        {renderView()}
      </Box>
      {openDialog === "okrLogs" && (
        <OKRLogDialog open={true} onClose={handleCloseDialog} />
      )}
      {openDialog === "teamManagement" && (
        <TeamManagementDialog open={true} onClose={handleCloseDialog} />
      )}
    </Box>
  );
};

export default OKRPage;
