import React, { useContext, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Divider } from '@mui/material';
import { OKRContext } from '../../context/OKRContext';
import { useAuth } from "../../customs/authService";

const OKRLogDialog = ({ open, onClose }) => {
  const { OKRLogs, fetchOKRLogs } = useContext(OKRContext);
  const hasFetchedLogs = useRef(false); // Track if logs have been fetched
  const { t } = useAuth();

  useEffect(() => {
    if (open && !hasFetchedLogs.current) {
      fetchOKRLogs();
      hasFetchedLogs.current = true;
    }
  }, [open, fetchOKRLogs]);

  const getAvatarUrl = (name) => {
    const initials = name.split(' ').map(word => word[0]).join('');
    return `https://ui-avatars.com/api/?name=${initials}&background=random&color=fff`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        style: {
          height: '450px', // Set the height of the dialog
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <DialogTitle>{t("okr.l_title")}</DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          maxHeight: 'calc(100% - 64px)', // Adjust the height considering DialogTitle height
          overflowY: 'auto',
        }}
      >
        <List>
          {OKRLogs && OKRLogs.length > 0 ? (
            OKRLogs.map((log, index) => (
              <React.Fragment key={log.okrLogId}>
                <ListItem sx={{ padding: '8px' }}>
                  <ListItemAvatar>
                    <Avatar src={getAvatarUrl(log.userId)} alt={log.userId} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${log.changeType} - ${log.changeDetails}`}
                    secondary={new Date(log.changeDate).toLocaleDateString()}
                    sx={{
                      fontSize: '0.875rem',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                      whiteSpace: 'normal',
                    }}
                  />
                </ListItem>
                {index < OKRLogs.length - 1 && <Divider variant="inset" component="li" />}
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary">
              {t("okr.l_default")}
            </Typography>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default OKRLogDialog;
