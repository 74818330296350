import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import DropPDF from "../User/PDFDroper";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";

const ImportLaboratory = () => {
  const { t } = useAuth();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errors, setErrors] = useState([]);
  const [laboratories, setLaboratories] = useState([]);

  const handleDrop = (acceptedFiles) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : "");
  };

  useEffect(() => {
    if (file) {
      handleSubmit();
      fetchData();
    }
  }, [file]);

  const handleSubmit = async () => {
    if (!file) {
      toast.warning(t("importLab.selectFileToImport"));
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      showLoading();
      const response = await apiAuth.post(`/Import/importLab`, formData);
      if (response.status === 200) {
        toast.success(t("importLab.uploadSuccess"));
        setErrors([]);
        setFile(null); // Clear the file input
        fetchData();
        setFileName("");
      }
    } catch (error) {
      const { data } = error.response;
      setErrors(data?.errorMessages || []);
      console.log(data);
      toast.error(t(`importLab.${data?.message}`));
    } finally {
      hideLoading();
    }
  };

  const fetchData = async () => {
    try {
      const response = await apiAuth.get("/Import/GetLaboratory");
      setLaboratories(response.data);
    } catch (error) {
      toast.error("Failed to load laboratories.");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={8}>
        <Paper style={{ margin: "16px", padding: "16px" }}>
          <Typography variant="h5" gutterBottom>
            Import Laboratories from CSV
          </Typography>
          <DropPDF onDrop={handleDrop}/>
          {fileName &&
          <Typography
            variant="h6"
            style={{ margin: "8px", display: "inline-block" }}
          >
             {fileName}
          </Typography>
             }

          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ margin: "8px 0", display: "block" }}
          >
            Import File
          </Button> */}
          {errors.length > 0 && (
            <TableContainer
              component={Paper}
              style={{ maxHeight: "300px", overflowY: "auto" }} // Giới hạn chiều cao và thêm thanh cuộn
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Lab Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      Errors
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((error, index) => {
                    const parts = error.split(":");
                    const lineNumber = parts[0];
                    const errorMessages = parts
                      .slice(1)
                      .join(":")
                      .trim()
                      .split(".")
                      .filter((msg) => msg);

                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          {lineNumber}
                        </TableCell>
                        <TableCell style={{ color: "red" }}>
                          {errorMessages.map((msg, msgIndex) => (
                            <li key={msgIndex}>
                              {t(`importLab.${msg.trim()}`)}
                            </li>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Grid>
      <Grid item xs={10}>
        <Paper style={{ margin: "16px", padding: "16px" }}>
          <Typography variant="h5" gutterBottom>
            Laboratory List
          </Typography>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "300px", overflowY: "auto" }} // Giới hạn chiều cao và thêm thanh cuộn
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Lab Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Check-In Time
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Check-Out Time
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Leave Request Number
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ fontSize: "18px", fontWeight: "bold" }}
                  >
                    Lab Manager Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {laboratories.map((lab) => (
                  <TableRow key={lab.Id}>
                    <TableCell style={{ fontSize: "16px" }}>
                      {lab.labName}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.checkInTime}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.checkOutTime}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "16px" }}>
                      {lab.leaveRequestNum}
                    </TableCell>
                    <TableCell style={{ fontSize: "16px" }}>
                      {lab.labManagerEmail}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ImportLaboratory;
