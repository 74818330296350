import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import "./assets/configs/language"

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const container = document.getElementById("root");
const root = createRoot(container);
// if (process.env.NODE_ENV === "development") {
//   const resizeObserverErrSilencer = function () {
//     const resizeObserverErr = window.console.error;
//     window.console.error = (...args) => {
//       if (args[0]?.includes('ResizeObserver loop limit exceeded')) {
//         console.warn('ResizeObserver loop limit exceeded'); // Log the warning
//         return; // Suppress the error
//       }
//       resizeObserverErr(...args);
//     };
//   };

//   resizeObserverErrSilencer();
// }
root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <App />
  </GoogleOAuthProvider>
);
