import React from "react";

// Set the worker URL to where your worker files are stored
const ViewPDF = ({ pdfUrl }) => {
  return (
      <embed
        src={pdfUrl}
        type="application/pdf"
        style={{ width: '40vw', height: '90vh', border: 'none' }}
      />
  );
};

export default ViewPDF;
