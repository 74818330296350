// LoadingManager.jsx
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

let isLoading = false;
let subscribers = [];

const setLoading = (value) => {
  isLoading = value;
  subscribers.forEach((callback) => callback(isLoading));
};

const subscribeLoading = (callback) => {
  subscribers.push(callback);
};

const unsubscribeLoading = (callback) => {
  subscribers = subscribers.filter((cb) => cb !== callback);
};

const GlobalLoading = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleLoadingChange = (loading) => {
      setVisible(loading);
    };

    subscribeLoading(handleLoadingChange);

    return () => {
      unsubscribeLoading(handleLoadingChange);
    };
  }, []);

  return (
    <Box
      sx={{
        display: visible ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(141, 140, 140, 0.47)', 
        zIndex: 9999, 
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

const showLoading = () => {
  setLoading(true);
};

const hideLoading = () => {
  setLoading(false);
};

export { GlobalLoading, showLoading, hideLoading };
