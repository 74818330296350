import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
} from "@mui/material";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";
import { showLoading, hideLoading } from "../Layout/LoadingScreen";

const MyCalendar = () => {
  const { t, auth } = useAuth();
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dialogMode, setDialogMode] = useState(""); // "view", "edit", "add", or "attendance"
  const [openDialog, setOpenDialog] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventDescription, setNewEventDescription] = useState("");
  const [newEventLocation, setNewEventLocation] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [attendance, setAttendance] = useState({});
  const [canEdit, setCanEdit] = useState(false);
  const isMentor = auth?.user?.roles.includes("Mentor");
  const isPastDate = (date, time) => {
    const selectedDateTime = new Date(`${date}T${time}:00`);
    const now = new Date();
    return selectedDateTime < now;
  };
  useEffect(() => {
    if (auth.user) {
      fetchUsers();
    }
  }, [auth.user]);

  const fetchEvents = async (start, end) => {
    try {
      showLoading();
      const response = await apiAuth.get("/Event/GetAllEvents");
      const eventsData = response.data.events;
      const formattedEvents = eventsData
        .filter(
          (event) =>
            event.attendeeIds.includes(auth.user.jwt) ||
            event.createBy === auth.user.userId
        )
        .map((event) => ({
          id: event.eventId.toString(),
          createBy: event.userId,
          title: event.title,
          description: event.description,
          start: event.startDate,
          end: event.endDate,
          location: event.location,
          selectedUsers: event.attendeeIds,
        }));
      setEvents(formattedEvents);
    } catch (error) {
      toast.error(
        error.response?.data.message
          ? t(`event.${error.response.data.message}`)
          : t("systemError")
      );
    } finally {
      hideLoading();
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await apiAuth.get("/Event/CreateEvent");
      setUsers(response.data.users);
    } catch (error) {
      error.response?.data.message
        ? t(`event.${error.response.data.message}`)
        : t("systemError");
    }
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedStartDate(selectInfo.startStr.split("T")[0]);
    setSelectedEndDate(selectInfo.endStr.split("T")[0]);
    setDialogMode("add");
    setOpenDialog(true);
  };

  const fetchAttendance = async (eventId) => {
    try {
      showLoading();
      const response = await apiAuth.get(`/Event/GetAttendance/${eventId}`);
      const attendanceData = response.data.attendance || [];
      const attendanceMap = attendanceData.reduce((acc, item) => {
        acc[item.userId] = item.status;
        return acc;
      }, {});
      setAttendance(attendanceMap);
    } catch (error) {
      toast.error(
        error.response?.data.message
          ? t(`event.${error.response.data.message}`)
          : t("systemError")
      );
      setAttendance({});
    } finally {
      hideLoading();
    }
  };

  const getAvatarUrl = (name) => {
    const words = name.split(" ");
    const initials = words[0][0] + words[words.length - 1][0]
    return `https://ui-avatars.com/api/?name=${initials}&background=random&color=fff`;
  };

  const handleEventClick = async (clickInfo) => {
    const event = clickInfo.event;
    const eventToShow = events.find((e) => e.id === event.id.toString());

    if (eventToShow) {
      setSelectedEvent(eventToShow);
      setSelectedUsers(eventToShow.selectedUsers || []);
      setNewEventTitle(eventToShow.title);
      setNewEventDescription(eventToShow.description);
      setNewEventLocation(eventToShow.location);
      setSelectedStartDate(eventToShow.start.split("T")[0]);
      setSelectedStartTime(eventToShow.start.split("T")[1].slice(0, 5));
      setSelectedEndDate(eventToShow.end.split("T")[0]);
      setSelectedEndTime(eventToShow.end.split("T")[1].slice(0, 5));
      setCanEdit(eventToShow.createBy === auth.user.jti || isMentor);
      setDialogMode("view");
      setOpenDialog(true);

      // Fetch and set attendance data
      await fetchAttendance(event.id); // Ensure await is used
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogMode("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    setNewEventTitle("");
    setNewEventDescription("");
    setNewEventLocation("");
    setSelectedStartTime("");
    setSelectedEndTime("");
    setSelectedUsers([]);
    setAttendance({});
  };

  const handleAddEvent = async () => {
    if (!isValidDateRange()) return; // Validation check
    if (
      isPastDate(selectedStartDate, selectedStartTime) ||
      isPastDate(selectedEndDate, selectedEndTime)
    ) {
      toast.error(t("event.toast_Event_past_date_error"));
      return;
    }
    if (
      newEventTitle &&
      selectedStartDate &&
      selectedStartTime &&
      selectedEndDate &&
      selectedEndTime &&
      selectedUsers.length > 0
    ) {
      const newEvent = {
        title: newEventTitle,
        description: newEventDescription,
        startDate: `${selectedStartDate}T${selectedStartTime}:00`,
        endDate: `${selectedEndDate}T${selectedEndTime}:00`,
        location: newEventLocation,
        attendeeIds: selectedUsers,
      };

      try {
        showLoading();
        const response = await apiAuth.post("/Event/CreateEvent", newEvent);
        if (response.status === 200) {
          const addedEvent = response.data.event;
          setEvents([
            ...events,
            {
              id: addedEvent.eventId,
              title: addedEvent.title,
              description: addedEvent.description,
              start: addedEvent.startDate,
              end: addedEvent.endDate,
              location: addedEvent.location,
              selectedUsers: addedEvent.attendeeIds,
            },
          ]);
          toast.success(t("event.toast_Event_add_s"));
          handleCloseDialog();
        } else {
          toast.error(response.data.Message || t("event.toast_Event_add_f"));
        }
      } catch (error) {
        toast.error(t("systemError"));
      } finally {
        fetchEvents();
        hideLoading();
      }
    }
  };

  const handleUpdateEvent = async () => {
    if (!isValidDateRange()) return; // Validation check

    if (selectedEvent) {
      const updatedEvent = {
        ...selectedEvent,
        title: newEventTitle,
        description: newEventDescription,
        startDate: `${selectedStartDate}T${selectedStartTime}:00`,
        endDate: `${selectedEndDate}T${selectedEndTime}:00`,
        location: newEventLocation,
        attendeeIds: selectedUsers,
      };

      try {
        showLoading();
        const response = await apiAuth.put(
          `/Event/UpdateEvent/${selectedEvent.id}`,
          updatedEvent
        );
        if (response.status === 200) {
          const updatedEventData = response.data.event;
          const updatedEvents = events.map((event) =>
            event.id === updatedEventData.eventId
              ? {
                  id: updatedEventData.eventId,
                  title: updatedEventData.title,
                  description: updatedEventData.description,
                  start: updatedEventData.startDate,
                  end: updatedEventData.endDate,
                  location: updatedEventData.location,
                  selectedUsers: updatedEventData.attendeeIds,
                }
              : event
          );
          setEvents(updatedEvents);
          toast.success(t("event.toast_Event_up_s"));
          handleCloseDialog();
        } else {
          toast.error(response.data.Message || t("event.toast_Event_up_f"));
        }
      } catch (error) {
        toast.error(t("systemError"));
      } finally {
        fetchEvents();
        hideLoading();
      }
    }
  };

  const handleDeleteEvent = async () => {
    if (selectedEvent) {
      try {
        showLoading();
        await apiAuth.delete(`/Event/DeleteEvent/${selectedEvent.id}`);
        setEvents(events.filter((event) => event.id !== selectedEvent.id));
        toast.success(t("event.toast_Event_del_s"));
        handleCloseDialog();
      } catch (error) {
        toast.error(t("systemError"));
      } finally {
        fetchEvents();
        hideLoading();
      }
    }
  };

  const handleAttendanceChange = (userId) => {
    setAttendance((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const handleAttendanceSubmit = async () => {
    if (selectedEvent) {
      try {
        showLoading();
        const attendanceData = Object.keys(attendance).map((userId) => ({
          userId,
          status: attendance[userId],
        }));
        const response = await apiAuth.post(
          `/Event/UpdateAttendance/${selectedEvent.id}`,
          { attendance: attendanceData }
        );
        if (response.status === 200) {
          toast.success(t("event.toast_Event_attendance_s"));
          handleCloseDialog();
        } else {
          toast.error(
            response.data.Message || t("event.toast_Event_attendance_f")
          );
        }
      } catch (error) {
        toast.error(t("systemError"));
      } finally {
        fetchEvents();
        hideLoading();
      }
    }
  };
  const isValidDateRange = () => {
    const startDateTime = new Date(
      `${selectedStartDate}T${selectedStartTime}:00`
    );
    const endDateTime = new Date(`${selectedEndDate}T${selectedEndTime}:00`);

    if (startDateTime >= endDateTime) {
      toast.error(t("event.toast_Event_datevalidate"));
      return false;
    }
    return true;
  };
  const renderDialogContent = () => {
    return (
      <>
        {dialogMode === "attendance" && selectedEvent && (
          <>
            <div>
              <Typography variant="h4" style={{ fontSize: "1.5rem" }}>
                {t("event.title")}: {selectedEvent.title}
              </Typography>
              <Typography
                variant="h4"
                marginY={2}
                style={{ fontSize: "1.5rem" }}
              >
                {t("event.location")}: {selectedEvent.location}
              </Typography>
              <Typography variant="subtitle1" style={{ fontSize: "1.2rem" }}>
                {t("event.start")}:{" "}
                {new Date(selectedEvent.start).toLocaleString()} -{" "}
                {t("event.end")}: {new Date(selectedEvent.end).toLocaleString()}
              </Typography>
            </div>
            <div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("event.avatar")}</TableCell>
                      <TableCell>{t("event.name")}</TableCell>
                      <TableCell>{t("event.roll")}</TableCell>
                      <TableCell>{t("event.attendance")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedEvent.selectedUsers.map((userId) => {
                      const user = users.find((user) => user.userId === userId);
                      const currentStatus = attendance[userId] || "absent"; // Ensure a default value
                      return (
                        <TableRow key={userId}>
                          <TableCell>
                            <Avatar
                              src={getAvatarUrl(user.fullName)}
                              alt={user.userId}
                            />
                          </TableCell>
                          <TableCell>
                            {user ? user.fullName : "User Name"}
                          </TableCell>
                          <TableCell>
                            {user ? user.rollName : "Roll Name"}
                          </TableCell>
                          <TableCell>
                            <FormControl component="fieldset" size="small">
                              <RadioGroup
                                row
                                value={currentStatus}
                                onChange={(e) =>
                                  setAttendance((prev) => ({
                                    ...prev,
                                    [userId]: e.target.value,
                                  }))
                                }
                              >
                                <FormControlLabel
                                  value="attended"
                                  control={<Radio />}
                                  label={t("event.e_Attendance_Status1")}
                                />
                                <FormControlLabel
                                  value="late"
                                  control={<Radio />}
                                  label={t("event.e_Attendance_Status2")}
                                />
                                <FormControlLabel
                                  value="absent"
                                  control={<Radio />}
                                  label={t("event.e_Attendance_Status3")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
        {dialogMode !== "attendance" && (
          <>
            <TextField
              margin="dense"
              label={t("event.title")}
              fullWidth
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
              disabled={dialogMode === "view"}
              inputProps={{ style: { fontSize: "1.2rem" } }}
            />
            <TextField
              margin="dense"
              label={t("event.description")}
              fullWidth
              value={newEventDescription}
              onChange={(e) => setNewEventDescription(e.target.value)}
              disabled={dialogMode === "view"}
              inputProps={{ style: { fontSize: "1.2rem" } }}
            />
            <TextField
              margin="dense"
              label={t("event.location")}
              fullWidth
              value={newEventLocation}
              onChange={(e) => setNewEventLocation(e.target.value)}
              disabled={dialogMode === "view"}
              inputProps={{ style: { fontSize: "1.2rem" } }}
            />
            <TextField
              margin="dense"
              label={t("event.start_Date")}
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={selectedStartDate}
              onChange={(e) => setSelectedStartDate(e.target.value)}
              disabled={dialogMode === "view"}
              inputProps={{
                style: { fontSize: "1.2rem" },
                min: new Date().toISOString().split("T")[0],
              }}
            />
            <TextField
              margin="dense"
              label={t("event.start_Time")}
              type="time"
              fullWidth
              value={selectedStartTime}
              onChange={(e) => setSelectedStartTime(e.target.value)}
              disabled={dialogMode === "view"}
              InputLabelProps={{ shrink: true }} // Ensure the label shrinks when the input has a value
              inputProps={{ style: { fontSize: "1.2rem" } }}
            />
            <TextField
              margin="dense"
              label={t("event.end_Date")}
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={selectedEndDate}
              onChange={(e) => setSelectedEndDate(e.target.value)}
              disabled={dialogMode === "view"}
              inputProps={{
                style: { fontSize: "1.2rem" },
                min: selectedStartDate
                  ? selectedStartDate
                  : new Date().toISOString().split("T")[0],
              }}
            />
            <TextField
              margin="dense"
              label={t("event.end_Time")}
              type="time"
              fullWidth
              value={selectedEndTime}
              onChange={(e) => setSelectedEndTime(e.target.value)}
              disabled={dialogMode === "view"}
              InputLabelProps={{ shrink: true }} // Ensure the label shrinks when the input has a value
              inputProps={{ style: { fontSize: "1.2rem" } }}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel style={{ fontSize: "1.2rem" }}>
                {t("event.select_Users")}
              </InputLabel>
              <Select
                multiple
                InputLabelProps={{ shrink: true }}
                label={t("event.select_Users")}
                value={selectedUsers}
                onChange={(e) => setSelectedUsers(e.target.value)}
                renderValue={(selected) =>
                  selected
                    .map((id) => {
                      const user = users.find((user) => user.userId === id);
                      return user ? user.fullName : "";
                    })
                    .join(", ")
                }
                disabled={dialogMode === "view"}
                inputProps={{ style: { fontSize: "1.2rem" } }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 150, // Set the maxHeight of the dropdown menu
                    },
                  },
                }}
              >
                {users.map((user) => (
                  <MenuItem
                    key={user.userId}
                    value={user.userId}
                    style={{ fontSize: "1rem", padding: "4px 8px" }} // Reduce font size and padding
                  >
                    {user.fullName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </>
    );
  };

  return (
    <Box>
      <FullCalendar
        height={"90vh"}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        selectable={true}
        events={events}
        select={handleDateSelect}
        eventClick={handleEventClick}
        datesSet={(arg) => {
          const start = arg.startStr.split("T")[0];
          const end = arg.endStr.split("T")[0];
          fetchEvents(start, end);
        }}
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{ fontSize: "1.5rem" }}>
          {dialogMode === "add"
            ? t("event.add_Event")
            : dialogMode === "view"
            ? t("event.event_Details")
            : dialogMode === "edit"
            ? t("event.update_Event")
            : t("event.attendance")}
        </DialogTitle>
        <DialogContent>{renderDialogContent()}</DialogContent>
        <DialogActions>
          {dialogMode === "add" && (
            <>
              <Button onClick={handleAddEvent} style={{ fontSize: "1.2rem" }}>
                {t("event.save")}
              </Button>
              <Button
                onClick={handleCloseDialog}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.cancel")}
              </Button>
            </>
          )}
          {dialogMode === "view" && (
            <>
              {canEdit && (
                <Button
                  onClick={() => setDialogMode("edit")}
                  style={{ fontSize: "1.2rem" }}
                >
                  {t("event.edit")}
                </Button>
              )}
              {(canEdit || isMentor) && (
                <Button
                  onClick={() => setDialogMode("attendance")}
                  style={{ fontSize: "1.2rem" }}
                >
                  {t("event.take_Attendance")}
                </Button>
              )}
              <Button
                onClick={handleCloseDialog}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.close")}
              </Button>
            </>
          )}
          {dialogMode === "edit" && (
            <>
              <Button
                onClick={handleUpdateEvent}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.save")}
              </Button>
              <Button
                onClick={() => setDialogMode("view")}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.cancel")}
              </Button>
              <Button
                onClick={handleDeleteEvent}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.delete")}
              </Button>
            </>
          )}
          {dialogMode === "attendance" && (
            <>
              <Button
                onClick={handleAttendanceSubmit}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.save")}
              </Button>
              <Button
                onClick={() => setDialogMode("view")}
                style={{ fontSize: "1.2rem" }}
              >
                {t("event.cancel")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyCalendar;
