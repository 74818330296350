import React from 'react';
import { AppBar, Tabs, Tab, Toolbar, Typography, IconButton, Tooltip, Box, MenuItem, Select, FormControl, InputLabel, Paper } from '@mui/material';
import { Dashboard, Timeline, FormatListBulleted, People, Event } from '@mui/icons-material';
import { useAuth } from "../../customs/authService";

const NavBar = ({ currentView, onViewChange, onIconClick, teamId, onTeamChange, teams }) => {
  const { t } = useAuth();
  const handleTabChange = (event, newValue) => {
    if (["maintable", "timeline", "chart"].includes(newValue)) {
      onViewChange(newValue);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6" sx={{ flexGrow: 0 }}>
            {t("okr.okr")}
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <Tabs
              value={["maintable", "timeline", "chart"].includes(currentView) ? currentView : "maintable"}
              onChange={handleTabChange}
              textColor="inherit"
              indicatorColor="secondary"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              <Tab value="maintable" label={t("okr.main_Table")} icon={<FormatListBulleted />} />
              <Tab value="timeline" label={t("okr.timeline")} icon={<Timeline />} />
              {/* <Tab value="chart" label={t("okr.dashboard")} icon={<Dashboard />} /> */}
            </Tabs>
            <FormControl variant="outlined" sx={{ marginLeft: 2, minWidth: 200, backgroundColor: 'whitesmoke'}}>
              {/* <InputLabel>{t("okr.select_team")}</InputLabel> */}
              <Select  
                value={teamId || ''}
                onChange={onTeamChange}
                disabled={!teams.length}
                // label={t("okr.select_team")}
              >
                {teams.map((team) => (
                  <MenuItem key={team.teamId} value={team.teamId}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Tooltip title={t("okr.log_Dialog")}>
            <IconButton color="inherit" onClick={() => onIconClick('okrLogs')}>
              <Event />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("okr.team_Dialog")}>
            <IconButton color="inherit" onClick={() => onIconClick('teamManagement')}>
              <People />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
