// src/components/Forbidden.js

import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../customs/authService';

const Forbidden = () => {
  const { t } = useAuth();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/");
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8, textAlign: 'center' }}>
      <Typography variant="h1" component="h1" gutterBottom>
        403
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Forbidden
      </Typography>
      <Typography variant="body1" gutterBottom>
      {t('errors.403')}
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleGoBack}>
          Go Home
        </Button>
      </Box>
    </Container>
  );
};

export default Forbidden;
