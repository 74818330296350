// src/components/Unauthorized.js

import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Unauthorized = ({ allowedRoles = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToLogin = () => {
    if (allowedRoles.every((item) => item.includes("System Admin"))) {
      navigate("/admin/login");
    } else {
      navigate("/login");
    }
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ mt: 8, textAlign: "center" }}
    >
      <Typography variant="h1" component="h1" gutterBottom>
        401
      </Typography>
      <Typography variant="h5" component="h2" gutterBottom>
        Unauthorized
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('errors.401')}
      </Typography>
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleGoToLogin}>
          Go to Login
        </Button>
      </Box>
    </Container>
  );
};

export default Unauthorized;
