import React from "react";
import { Box, Typography } from "@mui/material";
import DifferenceIcon from '@mui/icons-material/Difference';
import { useDropzone } from "react-dropzone";
import { useAuth } from "../../customs/authService";

const DropPDF = ({ onDrop }) => {
  const {t} = useAuth()
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "application/pdf",
  });
  return (
    <Box
      {...getRootProps()}
      sx={{
        border: "2px dashed grey",
        bgcolor: "whitesmoke",
        padding: 2,
        textAlign: "center",
        marginBottom: 2,
      }}
    >
      <input {...getInputProps()} />
      <DifferenceIcon color="inherit" />
      <Typography>{t('userProfile.fileUpload')}</Typography>
    </Box>
  );
};

export default DropPDF;
