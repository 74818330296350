import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  Event,
  Forum,
  ImportContacts,
  ImportExport,
} from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MyCalendar from "../../components/Event/EventCalendar";
import Profile from "../../components/User/Profile";
import AttendanceDashboard from "../../components/Attendance/AttendanceDashBoard";
import ImportCsvComponent from "../../components/Import/ImportCsvComponent";
import ImportLaboratory from "../../components/Import/ImportLaboratory";
import ImportMentor from "../../components/Import/ImportMentor";
import CandidateList from "../../components/Import/CandidateList";
import OKRPage from "../../components/Okr/OKRPage";
import MainTableView from "../../components/Okr/MainTableView";
import TimelineView from "../../components/Okr/TimelineView";
import DashboardView from "../../components/Okr/DashboardView";
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import StyleIcon from '@mui/icons-material/Style';
import TicketPage from "../../components/TicketModal/TicketPage";
import ReportTable from "../../components/Report/ReportDashBoard";
import DashboardAll from "../../components/Dashboard/DashboardAll";
import AddReportPage from "../../components/Report/AddReport";
import SemesterAd from "../../components/Report/SemesterAd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DashBoardLabManage from "../../components/Dashboard/DashBoardLab";
import MentorInternTabs from "../../components/User/Members";
import PeopleIcon from '@mui/icons-material/People';

export const menuItems = [
  {
    icon: <HomeIcon />,
    primaryText: "home",
    href: "/",
    allowedRoles: ["Mentor", "Intern", "System Admin"],
  },
  {
    icon: <DashboardIcon />,
    primaryText: "dashboardLab",
    href: "/dashboardLab",
    allowedRoles: ["Lab Manager"],
  },
  {
    icon: <PeopleIcon />,
    primaryText: "members",
    href: "/members",
    allowedRoles: ["Lab Manager"]
  },
  {
    icon: <DomainVerificationIcon />,
    primaryText: "attendance",
    href: "/attendance-list",
    allowedRoles: ["Intern", "Lab Manager", "Mentor"],
  },
  {
    icon: <Forum />,
    primaryText: "okr",
    href: "/okr",
    allowedRoles: ["Intern", "Mentor"],
  },
  {
    icon: <BarChartIcon />,
    primaryText: "report",
    href: "/report",
    allowedRoles: ["Mentor", "Lab Manager"],
  },
  {
    icon: <Event />,
    primaryText: "event",
    href: "/events",
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
  },
  {
    icon: <ImportContacts />,
    primaryText: "importIntern",
    href: "/importCSV",
    allowedRoles: ["System Admin"],
  },
  {
    icon: <ImportExport />,
    primaryText: "importLab",
    href: "/importLaboratory",
    allowedRoles: ["System Admin"],
  },
  {
    icon: <ImportExport />,
    primaryText: "importMentor",
    href: "/importMentor",
    allowedRoles: ["Lab Manager"],
  },
  {
    icon: <GroupAddIcon />,
    primaryText: "candidate",
    href: "/candidates",
    allowedRoles: ["Lab Manager", "Mentor"],
  },
  {
    icon: <StyleIcon />,
    primaryText: "ticket",
    href: "/ticket",
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
  },

  {
    icon: <DashboardIcon />,
    primaryText: "semester",
    href: "/semester",
    allowedRoles: ["System Admin"],
  },
];

export const protectedRoutes = [
  {
    allowedRoles: ["Mentor", "Intern"],
    component: <DashboardAll />,
    path: "/dashboard",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <DashBoardLabManage />,
    path: "/dashboardLab",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <MentorInternTabs />,
    path: "/members",
  },
  {
    allowedRoles: ["System Admin"],
    component: <SemesterAd />,
    path: "/semester",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <DashboardAll />,
    path: "/",
  },

  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <MyCalendar />,
    path: "/events",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <Profile />,
    path: "/user-profile",
  },
  {
    allowedRoles: ["Mentor", "Intern"],
    component: <OKRPage />,
    path: "/okr",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <MainTableView />,
    path: "/maintable",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <TimelineView />,
    path: "/timeline",
  },
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    component: <DashboardView />,
    path: "/chart",
  },
  {
    allowedRoles: ["Lab Manager", "Mentor"],
    component: <CandidateList />,
    path: "/candidates",
  },
  {
    allowedRoles: ["Lab Manager", "Intern", "Mentor"],
    component: <AttendanceDashboard />,
    path: "/attendance-list",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportCsvComponent />,
    path: "/importCSV",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportLaboratory />,
    path: "/importLaboratory",
  },
  {
    allowedRoles: ["Lab Manager"],
    component: <ImportMentor />,
    path: "importMentor",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportCsvComponent />,
    path: "/importCSV",
  },
  {
    allowedRoles: ["System Admin"],
    component: <ImportLaboratory />,
    path: "/importLaboratory",
  },
  {
    allowedRoles: ["Intern", "Mentor", "Lab Manager"],
    component: <TicketPage />,
    path: "/ticket",
  },
  {
    allowedRoles: ["Mentor"],
    component: <ReportTable />,
    path: "/report",
  },
  {
    allowedRoles: ["Mentor"],
    component: <AddReportPage />,
    path: "/add-report",
  },
];

export const accountMenus = [
  {
    allowedRoles: ["System Admin", "Lab Manager", "Mentor", "Intern"],
    label: "profile",
    path: "/user-profile",
  },
];
