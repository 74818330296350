import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./customs/authService";
import Unauthorized from "./components/Authenticate/Unauthorized"
import { ToastContainer } from "react-toastify";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { Suspense, lazy } from "react";
import { protectedRoutes } from "./assets/configs/routes";
import { OKRProvider } from "./context/OKRContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import PrivateRoute from "./components/Authenticate/PrivateRoute"
import NotFound from "./components/Authenticate/NotFound"
import { GlobalLoading } from "./components/Layout/LoadingScreen";
import { NotificationProvider } from "./context/NotificationContext";
import { CandidatesProvider } from "./context/CandidatesContext";
import ImportCsvComponent from "./components/Import/ImportCsvComponent";
import LanguageSpeedDial from "./components/Layout/SpeedDialLang";
import Layout from "./components/Layout/Layout";

const theme = createTheme();
const AdminAuthenticate = lazy(() =>
  import("./components/Authenticate/AdminAuthenticate")
);
const Authenticate = lazy(() => import("./components/Authenticate/Authenticate"));

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<div>Loading...</div>}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Router>
              <AuthProvider>
                <LanguageSpeedDial />
                <NotificationProvider>
                  <OKRProvider>
                    <CandidatesProvider>
                      <Routes>
                        <Route path="/login" element={<Authenticate />} />
                        <Route
                          path="/admin/login"
                          element={<AdminAuthenticate />}
                        />
                        <Route
                          path="/unauthorized"
                          element={<Unauthorized />}
                        />
                        <Route
                          path="/importCV/:signature"
                          element={<ImportCsvComponent />}
                        />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={<Layout />}>
                          {protectedRoutes.map((item, index) => (
                            <Route
                              key={`path-${index}`}
                              path={item.path}
                              element={
                                <PrivateRoute
                                  allowedRoles={item.allowedRoles}
                                  element={item.component}
                                />
                              }
                            />
                          ))}
                        </Route>
                      </Routes>
                    </CandidatesProvider>
                  </OKRProvider>
                </NotificationProvider>
              </AuthProvider>
            </Router>
          </LocalizationProvider>
        </Suspense>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          newestOnTop={true}
          hideProgressBar
          pauseOnHover
          theme="colored"
        />
        <GlobalLoading />
      </ThemeProvider>
    </>
  );
};

export default App;
