import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import apiAuth from "../../customs/axiosCustom";
import TicketModal from "../TicketModal/TicketModal";
import CreateTicketAttendance from "../TicketModal/CreateTicketAttendance";
import AutoBatchAttendanceToggle from "./AutoBatchAttendanceToggle";
import DropdownSettings from "../DayOff/DropdownSetting";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  Collapse,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useAuth } from "../../customs/authService";
import EditIcon from "@mui/icons-material/Edit";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import InternAttendance, {
  calculateAttendanceSummary,
  getStatusDisplay,
} from "./InternAttendanceDashboard";

const AttendanceDashboard = () => {
  const { t, auth, isIntern, isTesting } = useAuth(); // Lấy role từ useAuth
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [expandedStudent, setExpandedStudent] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [showCreateTicketModal, setShowCreateTicketModal] = useState(false);
  const [labInfo, setLabInfo] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [editAttendance, setEditAttendance] = useState(null);
  const [initialAttend, setInitialAttend] = useState(null);

  const getMonthStartAndEnd = (month, year) => {
    const startDate = new Date(year, month - 1, 2).toISOString().split("T")[0];
    const endDate = new Date(year, month, 1).toISOString().split("T")[0];
    return { startDate, endDate };
  };

  const fetchLabInfo = async () => {
    try {
      const response = await apiAuth.get("/Attendance/getLabInfo");
      setLabInfo(response?.data);
    } catch (error) {
      console.error("Error fetching lab info:", error);
      toast.error("Error fetching laboratory infomations");
    }
  };

  const apiEndpoint = auth.user?.roles.includes("Intern")
    ? "/Attendance/attendanceLst"
    : "/Attendance/labAttendanceLst"

  const fetchData = async (
    keyword = "",
    page = 1,
    month = selectedMonth,
    year = selectedYear
  ) => {
    try {
      const { startDate, endDate } = getMonthStartAndEnd(month, year);

      // Đảm bảo định dạng ngày theo yêu cầu của API
      const formattedStartDate = new Date(startDate)
        .toISOString()
        .split("T")[0];
      const formattedEndDate = new Date(endDate).toISOString().split("T")[0];

      const response = await apiAuth.get(apiEndpoint, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          PageNumber: auth?.user?.roles.includes("Intern") ? undefined : page, // Không gửi pageNumber cho Intern
          StartDate: formattedStartDate,
          EndDate: formattedEndDate,
          Keyword: keyword,
        },
      });

      const { lstAttendance, totalRecords } = response.data;
      console.log(response?.data, lstAttendance.attendances)

      setData(lstAttendance || []);
      if (!auth?.user?.roles.includes("Intern")) {
        setTotalPages(Math.ceil(totalRecords / 20));
        setTotalRecords(totalRecords);
      } else {
        setTotalPages(1); // Với Intern, chúng ta có một trang duy nhất
      }
    } catch (error) {
      setError(error);
      toast.error(t(error.response?.data?.message || "Error fetching data"));
      console.error("Error fetching attendance data:", error.response?.data);
    }
  };

  useEffect(() => {
    fetchData(searchKeyword, currentPage, selectedMonth, selectedYear);
  }, [currentPage, selectedMonth, selectedYear]);

  useEffect(() => {
    if (auth.user.roles.includes("Lab Manager")) {
      fetchLabInfo();
    }
  }, []);

  useEffect(() => {
    if (triggerSearch) {
      fetchData(searchKeyword, 1, selectedMonth, selectedYear);
      setCurrentPage(1);
      setTriggerSearch(false);
    }
  }, [triggerSearch]);

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setTriggerSearch(true);
  };

  const handleStatusChange = async (
    attendanceId,
    newStatus,
    checkInTime,
    checkOutTime
  ) => {
    if (auth?.user?.roles.includes("Intern")) return; // Intern không thể chỉnh sửa

    try {
      const response = await apiAuth.put(
        "/Attendance/updateStatus",
        { status: newStatus, checkInTime, checkOutTime, attendanceId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const updatedAttendance = response.data;
      setData((prevData) => {
        return prevData.map((record) => {
          const updatedAttendances =
            record.attendances?.map((attendance) => {
              if (attendance.id === updatedAttendance.id) {
                return {
                  ...attendance,
                  status: updatedAttendance.status,
                  checkInTime: updatedAttendance.checkInTime,
                  checkOutTime: updatedAttendance.checkOutTime,
                  reason: updatedAttendance.reason,
                  date: updatedAttendance.date.split("T")[0],
                };
              }
              return attendance;
            }) || []; // Đảm bảo rằng updatedAttendances không phải là undefined

          return {
            ...record,
            attendances: updatedAttendances,
          };
        });
      });
      handleEditModal();

      toast.success("Attendance updated successfully!");
    } catch (error) {
      toast.error(error?.response?.message || "Error when updating");
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleYearChange = (event) => {
    setSelectedYear(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleStudentClick = (studentId) => {
    setExpandedStudent(expandedStudent === studentId ? null : studentId);
  };

  const handleOpenTicketModal = () => {
    setShowTicketModal(true);
  };
  const handleCloseTicketModal = () => {
    setShowTicketModal(false);
  };
  const handleOpenCreateTicketModal = () => setShowCreateTicketModal(true);
  const handleCloseCreateTicketModal = () => setShowCreateTicketModal(false);

  const handleEditModal = (attendance, selectedStudent) => {
    setEditModal(!editModal);
    setEditAttendance(attendance);
    setInitialAttend(attendance);
    setSelectedStudent(selectedStudent);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    setEditAttendance((prevAttendance) => ({
      ...prevAttendance,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    handleStatusChange(
      editAttendance?.id,
      editAttendance?.status,
      editAttendance?.checkInTime,
      editAttendance?.checkOutTime
    );
  };

  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - 2 + i
  );

  return (
    <div className="attendance-dashboard">
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {t("attendance.attendanceList")}
        </Typography>
        {isIntern && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateTicketModal}
            sx={{ marginRight: "8px" }} // Thêm khoảng cách giữa các nút
          >
            {t("attendance.c_t_Attendance")}
          </Button>
        )}
        <CreateTicketAttendance
          show={showCreateTicketModal}
          onClose={handleCloseCreateTicketModal}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpenTicketModal}
        >
          {t("attendance.otAttendance")}
        </Button>
        {auth?.user?.roles.includes("Lab Manager") && (
          <DropdownSettings labInfo={labInfo} setLabInfo={setLabInfo} />
        )}
      </Box>
      <TicketModal show={showTicketModal} onClose={handleCloseTicketModal} />
      {!auth?.user?.roles.includes("Intern") && (
        <form
          onSubmit={handleSearchSubmit}
          className="search-form"
          style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}
        >
          <TextField
            label={t("attendance.search")}
            variant="outlined"
            value={searchKeyword}
            onChange={handleSearchChange}
            fullWidth
          />
          <Button type="submit" variant="contained" color="primary">
            {t("attendance.search")}
          </Button>
        </form>
      )}
      <div
        className="filters"
        style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}
      >
        <FormControl variant="outlined" className="month-filter" fullWidth>
          <InputLabel id="month-label">{t("attendance.month")}</InputLabel>
          <Select
            labelId="month-label"
            value={selectedMonth}
            onChange={handleMonthChange}
            label={t("attendance.month")}
          >
            {months.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className="year-filter" fullWidth>
          <InputLabel id="year-label">{t("attendance.year")}</InputLabel>
          <Select
            labelId="year-label"
            value={selectedYear}
            onChange={handleYearChange}
            label={t("attendance.year")}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {isIntern ? (
        <InternAttendance
          data={data}
          expandedStudent={expandedStudent}
          handleStudentClick={handleStudentClick}
          auth={auth}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  width={300}
                  sx={{ maxWidth: 400, fontWeight: "bold" }}
                >
                  {t("attendance.name")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.email")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.rollName")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.absent")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.attended")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.lateIn")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.soonOut")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>
                  {t("attendance.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((record, index) => {
                  const {
                    absentCount,
                    attendedCount,
                    lateInCount,
                    soonOutCount,
                  } = calculateAttendanceSummary(record.attendances || []);
                  return (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell>{record.internName}</TableCell>
                        <TableCell>{record.internEmail}</TableCell>
                        <TableCell>{record.rollName}</TableCell>
                        <TableCell>{absentCount}</TableCell>
                        <TableCell>{attendedCount}</TableCell>
                        <TableCell>{lateInCount}</TableCell>
                        <TableCell>{soonOutCount}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleStudentClick(record.internId)}
                            disabled={record.attendances.length === 0}
                          >
                            {expandedStudent === record.internId ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {expandedStudent === record.internId &&
                        record.attendances.length > 0 && (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Collapse in={true} timeout="auto" unmountOnExit>
                                <Table size="small">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.date")}
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.checkInTime")}
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.checkOutTime")}
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.status")}
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.reason")}
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: "bold" }}>
                                        {t("ticket.reason")}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {record.attendances.map(
                                      (attendance, idx) => (
                                        <TableRow key={idx}>
                                          <TableCell>
                                            {attendance.date}
                                          </TableCell>
                                          <TableCell>
                                            {attendance.checkInTime}
                                          </TableCell>
                                          <TableCell>
                                            {attendance.checkOutTime}
                                          </TableCell>
                                          <TableCell>
                                            {getStatusDisplay(
                                              attendance.status
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {attendance.reason}
                                          </TableCell>
                                          {auth.user.roles.includes(
                                            "Lab Manager"
                                          ) && (
                                            <TableCell>
                                              <IconButton
                                                size="large"
                                                edge="end"
                                                aria-haspopup="true"
                                                onClick={() =>
                                                  handleEditModal(
                                                    attendance,
                                                    record
                                                  )
                                                }
                                                disabled={
                                                  new Date().toLocaleDateString("en-GB") ===
                                                  attendance.date
                                                }
                                                color="info"
                                              >
                                                <EditIcon />
                                              </IconButton>
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={9} sx={{ textAlign: "center" }}>
                    No records found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {auth.user.roles !== "Intern" && (
          <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={totalRecords} // Cập nhật để phù hợp với số trang
            rowsPerPage={20}
            page={currentPage - 1}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      {auth.user.roles.includes("Lab Manager") && (
        <EditModal
          attendance={editAttendance}
          open={editModal}
          handleInputChange={handleEditInputChange}
          handleSubmit={handleEdit}
          handleOpen={handleEditModal}
          initial={initialAttend}
          laboratory={labInfo}
          expandedStudent={selectedStudent}
        ></EditModal>
      )}
    </div>
  );
};

const EditModal = ({
  attendance = {},
  handleInputChange,
  handleSubmit,
  open,
  handleOpen,
  laboratory,
  initial,
  expandedStudent,
}) => {
  const { t } = useAuth();
  const [isUpdate, setIsUpdate] = useState(false);

  // Hàm để so sánh sự khác biệt giữa hai đối tượng
  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    // Kiểm tra sự thay đổi giữa đối tượng attendance hiện tại và ban đầu
    if (!deepEqual(initial, attendance)) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [attendance]);

  return (
    <Dialog open={open}>
      <DialogTitle>{t("attendance.updateAttendance")}</DialogTitle>
      <DialogContent>
        <Typography sx={{ marginTop: 2 }}>
          {t("attendance.date", { date: attendance?.date ?? "" })}
        </Typography>
        <Typography sx={{ marginTop: 2 }}>
          {expandedStudent?.internName ?? expandedStudent?.internEmail}
        </Typography>
        <TextField
          sx={{ marginY: 2 }}
          label={t("settingLab.checkInTime")}
          InputLabelProps={{ shrink: true }}
          type="time"
          name="checkInTime"
          value={attendance?.checkInTime ?? ""}
          onChange={(e) => handleInputChange(e)}
          fullWidth
        />
        <TextField
          label={t("settingLab.checkOutTime")}
          InputLabelProps={{ shrink: true }}
          type="time"
          name="checkOutTime"
          value={attendance?.checkOutTime ?? ""}
          onChange={(e) => handleInputChange(e)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpen()} color="primary">
          {t("settingLab.cancel")}
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!isUpdate}
          color="primary"
        >
          {t("settingLab.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttendanceDashboard;
