import React, { createContext, useState } from "react";
import apiAuth from "../customs/axiosCustom";
import { hideLoading, showLoading } from "../components/Layout/LoadingScreen";
import { useAuth } from "../customs/authService";
import { toast } from "react-toastify";

export const OKRContext = createContext();

export const OKRProvider = ({ children }) => {
  const { auth } = useAuth();
  const [objectives, setObjectives] = useState([]);
  const [keyResults, setKeyResults] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [error, setError] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [OKRLogs, setOKRLogs] = useState([]);
  const [teams, setTeams] = useState([]);

  const fetchTeamId = async () => {
    try {
      showLoading();
      const response = await apiAuth.get("/OKR/GetUserTeamId");
      const teamsData = response.data?.teams || [];
      setTeams(teamsData);
      setTeamId(teamsData[0]?.teamId);
    } catch (error) {
      setError("Failed to fetch team ID");
      toast.error(
        "No teams available, please contact Mentor to access to Mentor team"
      );
      setTeams([]);
      setTeamId(null);
      setTeamMembers([]);
      setTeams([]);
      setOKRLogs([]);
    } finally {
      hideLoading();
    }
  };

  const fetchObjectives = async () => {
    try {
      showLoading();
      const response = await apiAuth.get(
        `/OKR/GetAllObjectives?currentTeamId=${teamId}`
      );
      setObjectives(response.data?.objectives || []);
    } catch (error) {
      setError("Failed to fetch objectives");
      setObjectives([]);
    } finally {
      hideLoading();
    }
  };

  const fetchKeyResults = async () => {
    try {
      showLoading();
      const response = await apiAuth.get(
        `/OKR/GetAllKeyResults?currentTeamId=${teamId}`
      );
      const objectiveIds = objectives.map((obj) => obj.objectiveId);
      const filteredKeyResults =
        response.data?.keyResults.filter((kr) =>
          objectiveIds.includes(kr.objectiveId)
        ) || [];
      setKeyResults(filteredKeyResults);
    } catch (error) {
      setError("Failed to fetch key results");
    } finally {
      hideLoading();
    }
  };

  const fetchTeamMembers = async () => {
    try {
      const response = await apiAuth.get(`/OKR/GetTeamMenber/${teamId}`);
      setTeamMembers(response.data?.teamMember || []);
    } catch (error) {
      setError("Failed to fetch team members");
    }
  };

  const fetchComments = async () => {
    try {
      const response = await apiAuth.get(
        `/OKR/GetAllComments?currentTeamId=${teamId}`
      );
      setAllComments(response.data?.comments || []);
    } catch (error) {
      setError("Failed to fetch comments");
    }
  };

  const fetchOKRLogs = async () => {
    try {
      const response = await apiAuth.get(
        `/OKR/GetAllOKRLog?currentTeamId=${teamId}`
      );
      const objectiveIds = objectives.map((obj) => obj.objectiveId);
      const filteredLogs =
        response.data?.okrLogs.filter((log) =>
          objectiveIds.includes(log.objectiveId)
        ) || [];
      const sorted = filteredLogs.sort(
        (a, b) => new Date(b.changeDate) - new Date(a.changeDate)
      );
      setOKRLogs(sorted);
    } catch (error) {
      console.error("Error fetching OKR logs:", error);
      setError("Failed to fetch OKR logs");
    }
  };

  const addObjective = async (objective) => {
    try {
      const userId = auth.user.jti;
      const roles = auth.user.roles;
      const isApprove = !roles.includes("Intern");

      const formattedObjective = {
        ...objective,
        teamId,
        startDate: new Date(objective.startDate).toISOString(),
        endDate: new Date(objective.endDate).toISOString(),
        isApprove,
        status: 1,
        createBy: userId,
      };

      const response = await apiAuth.post(
        `/OKR/CreateObjective?currentTeamId=${teamId}`,
        formattedObjective
      );
      setObjectives((prevObjectives) => [...prevObjectives, response.data]);
      fetchObjectives();
    } catch (error) {
      console.error("Error adding objective:", error);
    }
  };

  const updateObjective = async (updatedObjective) => {
    // const userId = auth.user.jti;
    // const roles = auth.user.roles;
    // const isApprove = !roles.includes('Intern');
    // const formattedObjective = {
    //     ...updatedObjective,
    //     teamId,
    //     startDate: new Date(updatedObjective.startDate).toISOString(),
    //     endDate: new Date(updatedObjective.endDate).toISOString(),
    //     isApprove,
    //     status: 1,
    //     createBy: userId
    // };
    const response = await apiAuth.put(
      `/OKR/UpdateObjective/${updatedObjective.objectiveId}`,
      updatedObjective
    );
    setObjectives((prevObjectives) =>
      prevObjectives.map((obj) =>
        obj.objectiveId === updatedObjective.objectiveId ? response.data : obj
      )
    );
    fetchObjectives();
  };

  const deleteObjective = async (id) => {
    try {
      await apiAuth.delete(`/OKR/DeleteObjective/${id}`);
      setObjectives((prevObjectives) =>
        prevObjectives.filter((obj) => obj.objectiveId !== id)
      );
      setKeyResults((prevKeyResults) =>
        prevKeyResults.filter((kr) => kr.objectiveId !== id)
      );
    } catch (error) {
      console.error("Error deleting objective:", error);
    }
  };

  const addKeyResult = async (keyResult) => {
    // Prepare the payload with only the required fields
    const payload = {
      objectiveId: keyResult.objectiveId,
      title: keyResult.title,
      assign: keyResult.assign || "", // Default to empty string if not provided
      priority: keyResult.priority || "Low", // Default to "Low" if not provided
      status: keyResult.status || 1, // Default to 1 if not provided
      notes: keyResult.notes || "",
      attachedFiles: keyResult.attachedFiles || "",
      targetValue: keyResult.targetValue || 0,
      currentValue: keyResult.currentValue || 0,
      estimateManDay: keyResult.estimateManDay || 0,
      actualManDay: keyResult.actualManDay || 0,
      startDate: keyResult.startDate || new Date().toISOString(),
      endDate: keyResult.endDate || new Date().toISOString(),
    };

    try {
      const response = await apiAuth.post("/OKR/CreateKeyResult", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Update the state with the new key result
      setKeyResults((prevKeyResults) => [
        ...prevKeyResults,
        response.data.keyResult,
      ]);
    } catch (error) {
      // Log detailed error information
      console.error("Error adding key result:", {
        message: error.message,
        status: error.response ? error.response.status : "No response status",
        data: error.response ? error.response.data : "No response data",
        config: error.config,
      });
    }
  };

  const updateKeyResult = async (updatedKeyResult) => {
    try {
      const response = await apiAuth.put(
        `/OKR/UpdateKeyResult/${updatedKeyResult.keyResultId}`,
        updatedKeyResult
      );
      setKeyResults((prevKeyResults) =>
        prevKeyResults.map((kr) =>
          kr.keyResultId === updatedKeyResult.keyResultId ? response.data : kr
        )
      );
      fetchKeyResults(updatedKeyResult.objectiveId);
    } catch (error) {
      console.error("Error updating key result:", error);
    }
  };

  const deleteKeyResult = async (id) => {
    try {
      await apiAuth.delete(`/OKR/DeleteKeyResult/${id}`);
      setKeyResults((prevKeyResults) =>
        prevKeyResults.filter((kr) => kr.keyResultId !== id)
      );
    } catch (error) {
      console.error("Error deleting key result:", error);
    }
  };

  const addComment = async (
    objectiveId,
    keyResultId,
    content,
    mentionedUserIds,
    attachedFiles,
    parentCommentId
  ) => {
    try {
      if (!content || !content.trim()) {
        throw new Error("Comment content cannot be empty.");
      }

      const userId = auth.user.jti;

      const commentDTO = {
        content: content.trim(),
        createById: userId,
        reletedObjectType: keyResultId ? "KeyResult" : "Objective",
        reletedObjectId: keyResultId || objectiveId,
        parentCommentId: parentCommentId || null,
        mentionedUserIds:
          mentionedUserIds.length > 0 ? mentionedUserIds.join(",") : null,
        attachedFiles:
          attachedFiles.length > 0 ? attachedFiles.join(",") : null,
      };

      const response = await apiAuth.post(
        `/OKR/CreateComment?currentTeamId=${teamId}`,
        commentDTO
      );
      if (response.data.message === "commentCreated") {
        setAllComments((prevComments) => [
          ...prevComments,
          response.data.comment,
        ]);
      }
    } catch (error) {
      console.error(
        "Error adding comment:",
        error.response ? error.response.data : error.message
      );
      setError(
        "Failed to add comment. Please check the details and try again."
      );
    }
  };

  const updateComment = async (
    commentId,
    objectiveId,
    keyResultId,
    updatedContent,
    mentionedUserIds,
    attachedFiles,
    parentCommentId
  ) => {
    try {
      const userId = auth.user.jti;

      const mentionedUserIdsArray = Array.isArray(mentionedUserIds)
        ? mentionedUserIds
        : [mentionedUserIds];

      const commentDTO = {
        commentId,
        parentCommentId: parentCommentId.length > 0 ? parentCommentId[0] : null, // Fix here
        createById: userId,
        content: updatedContent,
        reletedObjectType: keyResultId ? "KeyResult" : "Objective",
        reletedObjectId: keyResultId || objectiveId,
        mentionedUserIds: mentionedUserIdsArray.join(","),
        attachedFiles: attachedFiles,
      };

      const endpoint = `/OKR/UpdateComment/${commentId}?currentTeamId=${teamId}`;
      const response = await apiAuth.put(endpoint, commentDTO, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // const response = await apiAuth.put(`/OKR/UpdateComment/${commentId}`, commentDTO);
      if (response.data.message !== "commentUpdated") {
        throw new Error("Failed to update comment");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
      throw error;
    }
  };

  const deleteComment = async (commentId) => {
    try {
      // Construct the URL with query parameters
      const url = `/OKR/DeleteComment/${commentId}?currentTeamId=${teamId}`;
      const response = await apiAuth.delete(url);

      if (response.data.message !== "commentDeleted") {
        throw new Error("Failed to delete comment");
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
      throw error;
    }
  };
  const updateObjectiveStatus = async (objectiveId) => {
    const relatedKeyResults = keyResults.filter(
      (kr) => kr.objectiveId === objectiveId
    );
    let newStatus = 1;

    if (relatedKeyResults.length === 0) {
      newStatus = 1;
    } else if (relatedKeyResults.every((kr) => kr.status === 1)) {
      newStatus = 1;
    } else if (relatedKeyResults.some((kr) => kr.status === 2)) {
      newStatus = 2;
    } else if (relatedKeyResults.every((kr) => kr.status === 3)) {
      newStatus = 3;
    }
    const updatedObjective = objectives.find(
      (obj) => obj.objectiveId === objectiveId
    );
    if (updatedObjective && updatedObjective.status !== newStatus) {
      updatedObjective.status = newStatus;
      await updateObjective(updatedObjective); // Ensure this is awaited
    }
  };

  return (
    <OKRContext.Provider
      value={{
        teamId,
        objectives,
        keyResults,
        allComments,
        teamMembers,
        OKRLogs,
        teams,
        setTeamId,
        setObjectives,
        setTeamMembers,
        addObjective,
        updateObjective,
        deleteObjective,
        addKeyResult,
        updateKeyResult,
        deleteKeyResult,
        fetchOKRLogs,
        fetchComments,
        fetchTeamMembers,
        fetchObjectives,
        fetchKeyResults,
        addComment,
        updateComment,
        deleteComment,
        updateObjectiveStatus,
        error,
        fetchTeamId,
      }}
    >
      {children}
    </OKRContext.Provider>
  );
};
