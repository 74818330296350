import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { toast } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css";
import apiAuth from "../../customs/axiosCustom";
import TicketDetailsPage from "./TicketDetailsPage";
import { useAuth } from "../../customs/authService";

const TicketModal = ({ show, onClose }) => {
  const { t, auth } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  useEffect(() => {
    if (show) {
      setLoading(true);
      setError(null);
      const apiEndpoint =
        auth?.user?.roles.includes("Lab Manager") ||
        auth?.user?.roles.includes("Mentor")
          ? "/Tickets/getLabManagerTicketsAttendance"
          : "/Tickets/getTicketsAttendance";
      apiAuth
        .get(apiEndpoint, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setTickets(response.data);
          setLoading(false);
        })
        .catch(() => {
          setError(t("ticket.notFoundTickets"));
          setLoading(false);
        });
    }
  }, [show, auth]);

  const handleOpenDetails = (id) => {
    setSelectedTicketId(id);
    setShowDetails(true);
  };

  const handleCloseDetails = () => {
    setShowDetails(false);
  };

  const handleApprove = (id) => {
    apiAuth
      .post(
        "/Tickets/checkStatusTicketAttend",
        { id, status: "Approve" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data) {
          setTickets((prevTickets) =>
            prevTickets.filter((ticket) => ticket.id !== id)
          );
          setShowDetails(false);
          toast.success(t(response.data));
        }
      })
      .catch(() => {
        setError("Error approving ticket.");
        toast.error("Error approving ticket.");
      });
  };

  const handleReject = (id) => {
    const response = apiAuth
      .post(
        "/Tickets/checkStatusTicketAttend",
        { id, status: "Reject" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setTickets((prevTickets) =>
          prevTickets.filter((ticket) => ticket.id !== id)
        );
        setShowDetails(false);
        toast.success(t(response.data));
      })
      .catch(() => {
        setError(t(response.data.message));
        toast.error(t(response.data.message));
      });
  };

  const handleUpdateSuccess = () => {
    const apiEndpoint = auth?.user?.roles.includes("Lab Manager")
      ? "/Tickets/getLabManagerTicketsAttendance"
      : "/Tickets/getTicketsAttendance";
    apiAuth
      .get(apiEndpoint, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setTickets(response.data);
      })
      .catch(() => {
        setError("Error fetching updated tickets.");
      });
  };

  return (
    <>
      <Modal
        open={show && !showDetails}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        variant="h4"
      >
        <Box
          sx={{
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
            position: "relative",
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>

          <Typography id="modal-title" variant="h6" component="h2" mb={2}>
            {t("ticket.listAttendanceTicket")}
          </Typography>
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">{error}</Typography>
          ) : tickets.length === 0 ? ( // Kiểm tra nếu danh sách tickets rỗng
            <Typography>{t("ticket.notFoundTickets")}</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="ticket table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.iD")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.creator")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.assignee")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {t("ticket.title")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      {t("ticket.description")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.dateRequire")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.status")}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold", textAlign: "center" }}>
                      {t("ticket.actions")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((ticket) => (
                    <TableRow
                      key={ticket.id}
                      sx={{ backgroundColor: getStatusColor(ticket.status) }}
                    >
                      <TableCell sx={{ textAlign: "center" }}>
                        {ticket.id}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {ticket.nameCreator}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {ticket.nameAssignee}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 150,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.title}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {ticket.description}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {ticket.dateRequire
                          ? new Date(ticket.dateRequire).toLocaleDateString()
                          : "N/A"}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {getStatusText(ticket.status)}
                      </TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        {auth?.user?.roles.includes("Mentor") ? (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleApprove(ticket.id)}
                              sx={{
                                textAlign: "center",
                                mr: 1,
                                minWidth: "80px",
                              }}
                            >
                              {t("ticket.approve")}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleReject(ticket.id)}
                              sx={{ textAlign: "center", minWidth: "80px" }}
                            >
                              {t("ticket.reject")}
                            </Button>
                          </>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleOpenDetails(ticket.id)}
                            sx={{ textAlign: "center", minWidth: "80px" }}
                          >
                            {t("ticket.details")}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Modal>

      {showDetails && (
        <Modal
          open={showDetails}
          onClose={handleCloseDetails}
          aria-labelledby="details-modal-title"
          aria-describedby="details-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              maxHeight: "80vh",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseDetails}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>

            {selectedTicketId && (
              <TicketDetailsPage
                id={selectedTicketId}
                onClose={handleCloseDetails}
                onUpdateSuccess={handleUpdateSuccess}
              />
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

const getStatusText = (status) => {
  switch (status) {
    case 1:
      return "Pending";
    case 2:
      return "Approved";
    case 3:
      return "In Progress";
    case 4:
      return "Done";
    case 5:
      return "Rejected";
    default:
      return "Unknown";
  }
};

const getStatusColor = (status) => {
  switch (status) {
    case 1:
      return "#FFFFFF"; // White for Pending
    case 2:
      return "#ccffcc"; // Green for Approved
    case 3:
      return "#ffffcc"; // Yellow for In Progress
    case 4:
      return "#ccffcc"; // Green for Done
    case 5:
      return "#ffcccc"; // Red for Rejected
    default:
      return "#f0f0f0"; // Default background color
  }
};

export default TicketModal;
