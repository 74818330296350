import React, { createContext, useState, useEffect } from "react";
import apiAuth, { getCookie } from "../customs/axiosCustom";
import * as signalR from "@microsoft/signalr";
import { useAuth } from "../customs/authService";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { auth } = useAuth();
  const [notifications, setNotifications] = useState([]);

  const fetchNotifications = async () => {
    try {
      const response = await apiAuth.get("/OKR/GetAllNotifications");
      const sortedNotifications = response.data.notifications.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotifications(sortedNotifications);
    } catch (error) {
      setNotifications([]);
      console.error("Error fetching notifications:", error);
    }
  };

  const notificationConnect = async () => {
    const token = getCookie("jwtToken");
    const beUrl = process.env.REACT_APP_BE;

    // Ngắt kết nối SignalR cũ nếu có
    if (window.signalRConnection) {
      try {
        await window.signalRConnection.stop();
        console.log("Previous SignalR connection stopped.");
      } catch (error) {
        console.error("Error stopping previous SignalR connection:", error);
      }
    }

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${beUrl}/notificationHub`, {
        accessTokenFactory: () => token,
        transport:
          signalR.HttpTransportType.WebSockets |
          signalR.HttpTransportType.ServerSentEvents |
          signalR.HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => {
        console.log("SignalR connected.");
        connection.on("ReceiveNotification", (notificationDto) => {
          try {
            setNotifications((prevNotifications) => [
              notificationDto,
              ...prevNotifications,
            ]);
          } catch (error) {
            console.error("Error processing received notification:", error);
          }
        });
      })
      .catch((error) => {
        console.error("SignalR Connection Error:", error);
      });

    // Lưu kết nối mới vào window để tái sử dụng
    window.signalRConnection = connection;
  };

  useEffect(() => {
    if (auth?.user != null) {
      // Chạy fetchNotifications khi người dùng đăng nhập
      fetchNotifications();
      // Kết nối SignalR khi người dùng đăng nhập
      notificationConnect();
    }

    // Cleanup khi component unmount
    return () => {
      if (window.signalRConnection) {
        window.signalRConnection.stop();
        window.signalRConnection = null;
      }
    };
  }, [auth]); // Mỗi lần auth thay đổi, useEffect sẽ chạy lại

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
