import React, { useState } from "react";
import apiAuth from "../../customs/axiosCustom";
import { IconButton, Tooltip } from "@mui/material";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { hideLoading, showLoading } from "../Layout/LoadingScreen";
const AutoBatchAttendanceToggle = () => {
  const { t } = useTranslation();
  const [isOn, setIsOn] = useState(false);

  const handleToggle = async () => {
    try {
      // Gọi API khi nhấn nút
      showLoading();
      const response = await apiAuth.post(
        "/Attendance/autoBatchAttendance",
        isOn ? "off" : "on",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Cập nhật trạng thái nút
      if (response.status === 200) {
        setIsOn((prev) => !prev);
        toast.success(t(response?.data?.message));
      }
    } catch (error) {
      toast.error("Error while calling the API:", error);
    } finally {
      hideLoading();
    }
  };

  return (
    <Tooltip title="Run auto attendance batch (for testing)">
      <IconButton
        onClick={handleToggle}
        style={{ color: isOn ? "#1976d2" : "#000000" }}
      >
        {isOn ? (
          <ToggleOnIcon fontSize="large" />
        ) : (
          <ToggleOffIcon fontSize="large" />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default AutoBatchAttendanceToggle;
