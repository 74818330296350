import React, { useState, useContext, useEffect } from 'react';
import debounce from 'lodash/debounce'; // Import debounce from lodash
import {
  Dialog, DialogTitle, DialogContent, IconButton, TextField, List, ListItem, ListItemText,
  MenuItem, Menu, Typography, Avatar, ListItemAvatar, Grid, Box
} from '@mui/material';
import { OKRContext } from '../../context/OKRContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import apiAuth from '../../customs/axiosCustom';
import { useAuth } from "../../customs/authService";

// Helper function to format mentions
const formatMentions = (text, mentions = []) => {
  return mentions.reduce((acc, mention) => acc.replace(`@${mention.username}`, `<strong>@${mention.username}</strong>`), text);
};

const CommentWithReplies = ({
  comment,
  replies,
  onReplyChange,
  onAddReply,
  onUpdateComment,
  onDeleteComment,
  onUpdateReply,
  onDeleteReply,
  teamMembers
}) => {
  const [replyInput, setReplyInput] = useState('');
  const [replyMentionedUserIds, setReplyMentionedUserIds] = useState([]); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [editContent, setEditContent] = useState(comment.content);
  const [mentionAnchorEl, setMentionAnchorEl] = useState(null);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const open = Boolean(anchorEl);
  const openMentionSuggestions = Boolean(mentionAnchorEl);
  const { t } = useAuth();
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiAuth.get(`OKR/GetUser/${comment.createById}`);
        setUserDetails(response.data);
      } catch (error) {
        console.error('Error fetching user details', error); // Log error in fetching user details
      }
    };
    fetchUserDetails();
  }, [comment.createById]);

  const handleInputChange = (e, setInput, onChange, mentionedIdsSetter) => {
    const value = e.target.value;
    setInput(value);
    if (onChange) onChange(comment.commentId, value);

    const words = value.split(' ');
    const lastWord = words[words.length - 1];

    if (lastWord.startsWith('@') && lastWord.length > 1) {
      const mentionText = lastWord.substring(1);
      const suggestions = teamMembers.filter(member =>
        member.username.toLowerCase().startsWith(mentionText.toLowerCase())
      );
      setMentionSuggestions(suggestions);
      setMentionAnchorEl(e.currentTarget);
    } else {
      setMentionAnchorEl(null);
      setMentionSuggestions([]);
    }
  };

  const handleMentionClick = (username, setInput, input, setReplyMentionedUserIds, userId) => {
    const mentionTriggerIndex = input.lastIndexOf('@');
    const wordsBeforeMention = input.substring(0, mentionTriggerIndex);
    const remainingText = input.substring(mentionTriggerIndex).split(' ').slice(1).join(' ');

    const newValue = `${wordsBeforeMention}@${username} ${remainingText}`.trim();
    setInput(newValue);
    setReplyMentionedUserIds(prevIds => [...prevIds, userId]);
    setMentionAnchorEl(null);
    setMentionSuggestions([]);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleSaveClick = () => {
    if (editContent.trim()) {
      onUpdateComment(comment.commentId, editContent, replyMentionedUserIds); 
      setIsEditing(false);
      setReplyMentionedUserIds([]);
    } 
  };

  const handleAddReplyClick = () => {
    if (replyInput.trim()) {
      onAddReply(comment.commentId, replyInput.trim(), replyMentionedUserIds);
      setReplyInput('');
      setReplyMentionedUserIds([]);
      setIsReplying(false);
    } 
  };

  const menuItems = isEditing
    ? [
        <MenuItem key="save" onClick={handleSaveClick}>{t("okr.save")}</MenuItem>,
        <MenuItem key="cancel" onClick={() => setIsEditing(false)}>{t("okr.cancel")}</MenuItem>
      ]
    : [
        <MenuItem key="update" onClick={handleEditClick}>{t("okr.update")}</MenuItem>,
        <MenuItem key="delete" onClick={() => { onDeleteComment(comment.commentId); handleMenuClose(); }}>{t("okr.delete")}</MenuItem>
      ];

  return (
    <Box style={{ marginBottom: '16px', padding: '16px', border: '1px solid #ddd', borderRadius: '8px' }}>
      <ListItem alignItems="flex-start" style={{ padding: '8px 0' }}>
        <ListItemAvatar>
          <Avatar alt={`${userDetails.username}`} src={userDetails.avatarUrl} />
        </ListItemAvatar>
        <ListItemText
          primary={<Typography variant="subtitle1" component="span">{userDetails.username}</Typography>}
          secondary={
            isEditing ? (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  variant="outlined"
                  value={editContent}
                  onChange={(e) => handleInputChange(e, setEditContent, () => {}, setReplyMentionedUserIds)}
                  fullWidth
                  multiline
                  margin="dense"
                />
                <Menu
                  anchorEl={mentionAnchorEl}
                  open={openMentionSuggestions}
                  onClose={() => setMentionAnchorEl(null)}
                >
                  {mentionSuggestions.map((user) => (
                    <MenuItem key={user.userId} onClick={() => handleMentionClick(user.username, setEditContent, editContent, setReplyMentionedUserIds, user.userId)}>
                      {user.username}
                    </MenuItem>
                  ))}
                </Menu>
                <IconButton onClick={handleSaveClick} color="primary" style={{ marginLeft: '8px' }}>
                  <SendIcon />
                </IconButton>
                <IconButton onClick={() => setIsEditing(false)} color="secondary">
                  <CloseIcon />
                </IconButton>
              </Box>
            ) : (
              <>
                <Typography component="div" variant="body2" sx={{wordWrap: 'break-word',whiteSpace:'pre-wrap'}}>
                  <span dangerouslySetInnerHTML={{ __html: formatMentions(comment.content, comment.mentionedUsers) }} />
                </Typography>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="caption" color="textSecondary" style={{ marginRight: '8px' }} component="div">
                  {moment(comment.updatedAt).fromNow()}
                </Typography>
                  <IconButton size="small" onClick={() => setIsReplying(!isReplying)}>
                    <ReplyIcon />
                  </IconButton>
                </Box>
              </>
            )
          }
        />
        <IconButton edge="end" aria-label="more" onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          {menuItems}
        </Menu>
      </ListItem>
      <Box style={{ paddingLeft: '24px' }}>
        {replies.length > 0 && (
          <List>
            {replies.map((reply) => (
              <CommentWithReplies
                  key={reply.commentId}
                  comment={reply}
                  replies={reply.replies || []}
                  onReplyChange={onReplyChange}
                  onAddReply={onAddReply}
                  onUpdateComment={onUpdateReply}
                  onDeleteComment={onDeleteReply}
                  onUpdateReply={onUpdateReply}
                  onDeleteReply={onDeleteReply}
                  teamMembers={teamMembers}
              />
          ))}
          </List>
        )}
        {isReplying && (
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label={t("okr.reply")}
              variant="outlined"
              margin="normal"
              value={replyInput}
              onChange={(e) => handleInputChange(e, setReplyInput, onReplyChange, setReplyMentionedUserIds)}
              fullWidth
            />
            <Menu
              anchorEl={mentionAnchorEl}
              open={openMentionSuggestions}
              onClose={() => setMentionAnchorEl(null)}
            >
            {mentionSuggestions.map((user) => (
                <MenuItem key={user.userId} onClick={() => handleMentionClick(user.username, setReplyInput, replyInput, setReplyMentionedUserIds, user.userId)}>
                    {user.username}
                </MenuItem>
            ))}
            </Menu>
            <IconButton onClick={handleAddReplyClick} color="primary" style={{ marginLeft: '8px' }}>
              <SendIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CommentReplyDialog = ({ open, onClose, comments, onAddComment, onUpdateComment, onDeleteComment, onUpdateReply, onDeleteReply }) => {
  const [newComment, setNewComment] = useState('');
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [attachedFiles, setAttachedFiles] = useState('');
  const [replies, setReplies] = useState({});
  const [mentionAnchorEl, setMentionAnchorEl] = useState(null);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const openMentionSuggestions = Boolean(mentionAnchorEl);
  const [isTeamMembersFetched, setIsTeamMembersFetched] = useState(false);
  const { teamMembers, fetchTeamMembers } = useContext(OKRContext);
  const { t } = useAuth();

  // Only fetch team members if necessary
  useEffect(() => {
    if (open && !isTeamMembersFetched) {
      fetchTeamMembers().then(() => {
        setIsTeamMembersFetched(true);
      });
    }
  }, [open, isTeamMembersFetched, fetchTeamMembers]);

  const handleInputChange = (e, setInput, onChange, mentionedIdsSetter) => {
    const value = e.target.value;
    setInput(value);
    if (onChange) onChange(value);

    const words = value.split(' ');
    const lastWord = words[words.length - 1];

    if (lastWord.startsWith('@') && lastWord.length > 1) {
      const mentionText = lastWord.substring(1);
      const suggestions = teamMembers.filter(member =>
        member.username.toLowerCase().startsWith(mentionText.toLowerCase())
      );
      setMentionSuggestions(suggestions);
      setMentionAnchorEl(e.currentTarget);
    } else {
      setMentionAnchorEl(null);
      setMentionSuggestions([]);
    }
  };

  const handleMentionClick = (username, setInput, input, setMentionedUserIds, userId) => {
    const mentionTriggerIndex = input.lastIndexOf('@');
    const wordsBeforeMention = input.substring(0, mentionTriggerIndex);
    const remainingText = input.substring(mentionTriggerIndex).split(' ').slice(1).join(' ');

    const newValue = `${wordsBeforeMention}@${username} ${remainingText}`.trim();
    setInput(newValue);
    setMentionedUserIds(prevIds => [...prevIds, userId]);

    setMentionAnchorEl(null);
    setMentionSuggestions([]);
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      onAddComment(
        newComment.trim(),
        mentionedUserIds || [],
        attachedFiles || ''
      );
      setNewComment('');
      setMentionedUserIds([]);
      setAttachedFiles('');
    } 
  };

  const handleUpdateComment = (commentId, updatedContent, parentCommentId) => {
    if (updatedContent.trim()) {
      onUpdateComment(commentId, updatedContent.trim(), mentionedUserIds, attachedFiles, parentCommentId);
      setNewComment('');
      setMentionedUserIds([]);
      setAttachedFiles('');
    } 
  };

  const handleDeleteComment = (commentId) => {
    onDeleteComment(commentId);
  };

  const handleAddReply = (commentId, replyText, replyMentionedUserIds) => {
    if (replyText.trim()) {
      onAddComment(replyText.trim(), replyMentionedUserIds || [], attachedFiles || '', commentId);
      setReplies(prevReplies => ({ ...prevReplies, [commentId]: '' }));
    } 
  };

  const handleReplyChange = (commentId, text) => {
    setReplies(prevReplies => ({ ...prevReplies, [commentId]: text }));
  };

  const getCommentsWithReplies = () => {
    const groupedReplies = comments.reduce((acc, comment) => {
      if (comment.replies && comment.replies.length > 0) {
        comment.replies.forEach(reply => {
          if (!acc[reply.parentCommentId]) {
            acc[reply.parentCommentId] = [];
          }
          acc[reply.parentCommentId].push(reply);
        });
      }
      return acc;
    }, {});

    const commentsMap = {};
    comments.forEach(comment => {
      commentsMap[comment.commentId] = {
        ...comment,
        replies: groupedReplies[comment.commentId] || []
      };
    });

    const getHierarchicalComments = (parentId = null) => {
      return Object.values(commentsMap).filter(comment => comment.parentCommentId === parentId).map(comment => ({
        ...comment,
        replies: getHierarchicalComments(comment.commentId)
      }));
    };

    return getHierarchicalComments();
  };

  const hierarchicalComments = getCommentsWithReplies();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {t("okr.comments")}
        <IconButton onClick={onClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{ maxHeight: '70vh', overflowY: 'auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <List style={{ maxHeight: '50vh', overflowY: 'auto' }}>
              {hierarchicalComments.length === 0 ? (
                <ListItem>
                  <ListItemText primary={t("okr.no_Commnet")}/>
                </ListItem>
              ) : (
                hierarchicalComments.map(comment => (
                  <CommentWithReplies
                    key={comment.commentId}
                    comment={comment}
                    replies={comment.replies}
                    onReplyChange={handleReplyChange}
                    onAddReply={handleAddReply}
                    onUpdateComment={handleUpdateComment}
                    onDeleteComment={handleDeleteComment}
                    onUpdateReply={handleUpdateComment} 
                    onDeleteReply={handleDeleteComment} 
                    teamMembers={teamMembers}
                  />
                ))
              )}
            </List>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label={t("okr.comments")}
                fullWidth
                variant="outlined"
                margin="normal"
                value={newComment}
                onChange={(e) => handleInputChange(e, setNewComment, () => {})}
                multiline
                rows={2}
              />
              <Menu
                anchorEl={mentionAnchorEl}
                open={openMentionSuggestions}
                onClose={() => setMentionAnchorEl(null)}
              >
              {mentionSuggestions.map((user) => (
                <MenuItem
                    key={user.userId}
                    onClick={() => handleMentionClick(user.username, setNewComment, newComment, setMentionedUserIds, user.userId)}
                >
                      {user.username}
                  </MenuItem>
                ))}
              </Menu>
              <IconButton onClick={handleAddComment} color="primary" style={{ marginLeft: '8px' }}>
                <SendIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CommentReplyDialog;
