import { useState } from "react";
import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import CustomDrawer from "./Drawer";
import CustomAppBar from "./AppBar";
import { useAuth } from "../../customs/authService";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Layout = () => {
  const [open, setOpen] = useState(false);
  const { isIntern, isLogged } = useAuth();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", bgcolor: "#f0f0f0" }}>
      <CssBaseline />
      <CustomAppBar
        open={open}
        isIntern={isIntern}
        handleDrawerOpen={handleDrawerOpen}
        openedMixin={openedMixin}
        closedMixin={closedMixin}
      />
      {isLogged() ? (
        <>
          <CustomDrawer
            open={open}
            openedMixin={openedMixin}
            closedMixin={closedMixin}
            drawerWidth={drawerWidth}
            handleDrawerClose={handleDrawerClose}
          />
        </>
      ) : null}

      <Box component="main" sx={{ flexGrow: 1, pt: 7 }}>
        <Box sx={{ minHeight: "calc(100vh - 64px)", p: 3 }}>
          <Outlet isIntern={isIntern}/>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
