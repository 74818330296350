import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAuth } from "../../customs/authService";

const ObjectiveForm = ({ open, handleClose, onSave, initialData }) => {
  const [objective, setObjective] = useState(
    initialData || {
      title: "",
      description: "",
      startDate: new Date(),
      endDate: new Date(),
    }
  );
  const [errors, setErrors] = useState({});
  const { t } = useAuth();
  useEffect(() => {
    if (open) {
      setObjective(
        initialData || {
          title: "",
          description: "",
          startDate: new Date(),
          endDate: new Date(),
        }
      );
      setErrors({});
    }
  }, [initialData, open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setObjective((prevObjective) => ({ ...prevObjective, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleDateChange = (name, date) => {
    setObjective((prevObjective) => ({ ...prevObjective, [name]: date }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const tempErrors = {};
    if (!objective.title) tempErrors.title = "Title is required";
    if (!objective.description)
      tempErrors.description = "Description is required";
    if (!objective.startDate) tempErrors.startDate = "Start Date is required";
    if (!objective.endDate) tempErrors.endDate = "End Date is required";
    if (new Date(objective.startDate) >= new Date(objective.endDate))
      tempErrors.endDate = "End Date should be after Start Date";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      const newObjective = {
        ...objective,
      };
      onSave(newObjective);
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t("okr.add_O_Button")}</DialogTitle>
      <DialogContent>
        <TextField
          label={t("okr.title")}
          name="title"
          value={objective.title}
          onChange={handleChange}
          fullWidth
          margin="dense"
          error={!!errors.title}
          helperText={errors.title}
        />
        <TextField
          label={t("okr.description")}
          name="description"
          value={objective.description}
          onChange={handleChange}
          fullWidth
          margin="dense"
          error={!!errors.description}
          helperText={errors.description}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            display={"flex"}
            alignContent={"space-between"}
            sx={{ marginY: 2 }}
          >
            <DateTimePicker
              sx={{ marginRight: 2 }}
              label={t("okr.start_Date")}
              value={objective.startDate}
              maxDateTime={objective.endDate}
              onChange={(date) => handleDateChange("startDate", date)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  margin="dense"
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                />
              )}
            />
            <DateTimePicker
              label={t("okr.end_Date")}
              value={objective.endDate}
              minDateTime={objective.startDate}
              onChange={(date) => handleDateChange("endDate", date)}
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  margin="dense"
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectiveForm;
