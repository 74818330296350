import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Box,
  Button,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [totalObjectives, setObjectivesData] = useState([]);
  const [keyResultsData, setKeyResultsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartType, setChartType] = useState("line");
  const { t } = useAuth();

  useEffect(() => {
    const fetchSummaryData = async () => {
      try {
        const response = await apiAuth.get("/DashBoard/intern-summary");
        const data = response.data || {};

        if (
          Array.isArray(data.attendanceSummary) &&
          Array.isArray(data.keyResultsSummary)
        ) {
          setAttendanceData(data.attendanceSummary);
          setKeyResultsData(data.keyResultsSummary);
          setObjectivesData(data.totalObjectives);
        } else {
          setError("Invalid data format received from API");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSummaryData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  const attendanceLabels = attendanceData.map(
    (item) => `${item.month}/${item.year}`
  );
  const attendanceDatasets = [
    {
      label: t("report.attended"),
      data: attendanceData.map((item) => item.totalAttendanced || 0),
      backgroundColor: "rgba(0, 0, 255, 0.5)",
      borderColor: "blue",
      borderWidth: 1,
    },
    {
      label: t("report.absent"),
      data: attendanceData.map((item) => item.totalAbsent || 0),
      backgroundColor: "rgba(255, 0, 0, 0.5)",
      borderColor: "red",
      borderWidth: 1,
    },
    {
      label: t("report.lateIn"),
      data: attendanceData.map((item) => item.totalLateIn || 0),
      backgroundColor: "rgba(255, 165, 0, 0.5)",
      borderColor: "orange",
      borderWidth: 1,
    },
    {
      label: t("report.soonOut"),
      data: attendanceData.map((item) => item.totalSoonOut || 0),
      backgroundColor: "rgba(0, 255, 0, 0.5)",
      borderColor: "green",
      borderWidth: 1,
    },
  ];

  const attendanceChartData = {
    labels: attendanceLabels,
    datasets: attendanceDatasets,
  };

  // Prepare data for key results chart
  const keyResultsLabels = keyResultsData.map(
    (item) => `${item.month}/${item.year}`
  );
  const keyResultsDatasets = [
    {
      label: t("report.notStarted"),
      data: keyResultsData.map((item) => item.totalNotStarted || 0),
      backgroundColor: "rgba(255, 0, 0, 0.5)",
      borderColor: "red",
      borderWidth: 1,
    },
    {
      label: t("report.inProgress"),
      data: keyResultsData.map((item) => item.totalInProgress || 0),
      backgroundColor: "rgba(255, 165, 0, 0.5)",
      borderColor: "orange",
      borderWidth: 1,
    },
    {
      label: t("report.completed"),
      data: keyResultsData.map((item) => item.totalCompleted || 0),
      backgroundColor: "rgba(0, 255, 0, 0.5)",
      borderColor: "green",
      borderWidth: 1,
    },
    {
      label: t("report.failed"),
      data: keyResultsData.map((item) => item.totalFailed || 0),
      backgroundColor: "rgba(0, 0, 255, 0.5)",
      borderColor: "blue",
      borderWidth: 1,
    },
  ];

  const keyResultsChartData = {
    labels: keyResultsLabels,
    datasets: keyResultsDatasets,
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.raw;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Month/Year",
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Value",
        },
        ticks: {
          stepSize: 1, // Ensures that tick marks are whole numbers
          callback: function (value) {
            return Number.isInteger(value) ? value : ""; // Displays only integers
          },
        },
      },
    },
  };

  const handleChartTypeChange = () => {
    setChartType(chartType === "line" ? "bar" : "line");
  };

  return (
    <Card style={{ width: "100%", margin: "auto" }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {t("reportV.dashBoard")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ddd",
                padding: 2,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "400px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                {t("report.attendanceOverview")}
              </Typography>
              <div style={{ width: "100%", height: "100%" }}>
                {chartType === "line" ? (
                  <Line data={attendanceChartData} options={chartOptions} />
                ) : (
                  <Bar data={attendanceChartData} options={chartOptions} />
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: "1px solid #ddd",
                padding: 2,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "400px",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                {t("report.keyResultsOverview")}
              </Typography>
              <div style={{ width: "100%", height: "100%" }}>
                {chartType === "line" ? (
                  <Line data={keyResultsChartData} options={chartOptions} />
                ) : (
                  <Bar data={keyResultsChartData} options={chartOptions} />
                )}
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                marginTop: "20px",
                border: "1px solid #ddd",
                padding: 2,
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                width: "50%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ marginBottom: 1, fontWeight: "bold" }}
              >
                {t("reportV.totalObjectives")}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h5"
                  sx={{ color: "#2196f3", fontWeight: "bold" }}
                >
                  {totalObjectives}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
        <Button
          onClick={handleChartTypeChange}
          variant="contained"
          sx={{ marginTop: 5 }}
        >
          {t("report.toggleChartType")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Home;
