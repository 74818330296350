import React, { useState, useEffect } from "react";
import AttendanceModal from "./AttendanceModal";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { Check } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import apiAuth from "../../customs/axiosCustom";
import { toast } from "react-toastify";
import { useAuth } from "../../customs/authService";

const Attendance = () => {
  const { t } = useAuth();
  const [open, setOpen] = useState(false);
  const [isCheckIn, setIsCheckIn] = useState(false);
  const [checkNotify, setCheckNotify] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [checkInSubmitTime, setCheckInSubmitTime] = useState(null);
  const [checkOutSubmitTime, setCheckOutSubmitTime] = useState(null);
  const [faceVector, setFaceVector] = useState(null);
  const [submitUrl, setSubmitUrl] = useState("");
  const [count, setCount] = useState(0)

  useEffect(() => {
    // Function to fetch check-in and check-out times from the API
    const fetchTimes = () => {
      apiAuth
        .get("/attendance/get-lab-regist-info")
        .then((res) => {
          setCheckInTime(res?.data?.labTime?.checkInTime);
          setCheckOutTime(res?.data.labTime?.checkOutTime);
          setFaceVector(res?.data?.faceVector ? JSON.parse(res?.data?.faceVector) : null);
          setCheckInSubmitTime(res?.data?.isAttended?.checkInTime);
          setCheckOutSubmitTime(res?.data?.isAttended?.checkOutTime);
          setIsCheckIn(res?.data?.isAttended ?? false);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            toast.error(t('ipStrictError'))
          } else {
            toast.error(t('systemError'))
          }
        });
    };

    fetchTimes();
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const currentTime = now.toTimeString().slice(0, 8);
      if (currentTime >= checkInTime && !checkInSubmitTime) {
        setSubmitUrl("/attendance/attendanceIn");
        setCheckNotify(true);
      } else if (currentTime >= checkOutTime && checkInSubmitTime && !checkOutSubmitTime) {
        setSubmitUrl("/attendance/attendanceOut");
        setCheckNotify(true);
      } else {
        setCheckNotify(false);
      }
      setCount(count + 1)
    };

    const intervalId = setInterval(checkTime, 1000);

    return () => clearInterval(intervalId);
  }, [count]);

  const handleClose = () => setOpen(false);
  const handleCheckNotifyClose = () => setCheckNotify(false);
  const handleClick = () => {
    if (isCheckIn !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <>
      <Tooltip
        title={isCheckIn ? t("attendance.checkOut") : t("attendance.checkIn")}
      >
        <span>
          <IconButton
            color="info"
            size="large"
            disabled={!checkNotify}
            onClick={handleClick}
          >
            <Badge badgeContent={checkNotify ? "N" : null} color="error">
              {isCheckIn ? <LogoutIcon /> : <Check />}
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <AttendanceModal
        open={open}
        isCheckIn={isCheckIn}
        handleClose={handleClose}
        faceVector={faceVector}
        submitUrl={submitUrl}
        handleCheckIn={setIsCheckIn}
        handleCheckInSubmitTime={setCheckInSubmitTime}
        handleCheckOutSubmitTime={setCheckOutSubmitTime}
        handleSetNotify={handleCheckNotifyClose}
        handleFaceVector={setFaceVector}
      />
    </>
  );
};

export default Attendance;
