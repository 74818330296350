import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { useAuth } from "../../customs/authService";

function Account({
  anchorE2,
  handleAccountClick,
  handleAccountClose,
  handleAccountMenu,
  accountMenus,
  logout,
}) {
  const { t } = useAuth();

  return (
    <>
      <Tooltip title={t("account")}>
        <span>
          <IconButton
            size="large"
            edge="end"
            aria-haspopup="true"
            onClick={handleAccountClick}
            color="info"
          >
            <AccountCircle />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={anchorE2}
        open={Boolean(anchorE2)}
        onClose={handleAccountClose}
      >
        {accountMenus &&
          accountMenus.map((item, index) => (
            <MenuItem
              key={`accountMenu-${index}`}
              onClick={() => handleAccountMenu(item.path)}
            >
              {t(item.label)}
            </MenuItem>
          ))}
        <MenuItem onClick={logout} sx={{ color: "red" }}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
