import React, { useState, useEffect } from "react";
import { useAuth } from "../../customs/authService";
import apiAuth from "../../customs/axiosCustom";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  MenuItem,
  Select,
  Button,
  Dialog,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import TicketDetailsPage from "./TicketDetailsPage"; // Import TicketDetailsPage
import CreateTicketPage from "../TicketModal/CreateTicket"; // Import CreateTicketPage
import CloseIcon from "@mui/icons-material/Close";
import "./TicketPage.css"; // Import CSS
import { toast } from "react-toastify"; // Import toast

const TicketPage = () => {
  const { t, auth } = useAuth(); // Lấy role từ useAuth
  const [tickets, setTickets] = useState([]);
  const [status, setStatus] = useState("");
  const [keyword, setKeyword] = useState("");
  const [selectedTicketId, setSelectedTicketId] = useState(null); // Trạng thái để lưu ticket được chọn
  const [open, setOpen] = useState(false); // Trạng thái để mở/đóng modal chi tiết ticket
  const [createModalOpen, setCreateModalOpen] = useState(false); // Trạng thái để mở/đóng modal tạo ticket

  // Xác định endpoint dựa trên vai trò của người dùng
  const apiEndpoint = auth?.user?.roles.includes("Lab Manager")
    ? "/Tickets/getLabManagerTicketsOther"
    : "/Tickets/getTickets";

  useEffect(() => {
    fetchTickets(); // Gọi hàm fetchTickets khi component được mount lần đầu tiên
  }, [apiEndpoint, auth.token, status]);

  const fetchTickets = async () => {
    try {
      const response = await apiAuth.get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        params: {
          status: status,
          keyword: keyword,
        },
      });
      setTickets(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching tickets:", error);
      toast.error(t(error.response?.data?.message));
      setTickets([]); // Đặt tickets là một mảng rỗng trong trường hợp lỗi
    }
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleKeywordChange = (e) => {
    setKeyword(e.target.value);
  };

  const handleSearch = () => {
    fetchTickets(); // Gọi hàm fetch khi người dùng nhấn nút tìm kiếm
  };

  const handleOpenModal = (ticketId) => {
    setSelectedTicketId(ticketId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedTicketId(null);
  };

  const handleUpdateSuccess = () => {
    fetchTickets();
  };

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCreateSuccess = () => {
    fetchTickets();
    handleCloseCreateModal();
  };

  return (
    <div className="ticket-page">
      <h1>{t("ticket.ticketList")}</h1>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginY: 2 }}
      >
        <TextField
          label={t("attendance.search")}
          variant="outlined"
          value={keyword}
          onChange={handleKeywordChange}
          sx={{ flexGrow: 1, marginRight: 2 }}
        />
        <Select
          value={status}
          onChange={handleStatusChange}
          displayEmpty
          sx={{ width: 200, marginRight: 2 }}
        >
          <MenuItem value="">{t(`ticket.statusText.all`)}</MenuItem>
          <MenuItem value="1">{t(`ticket.statusText.1`)}</MenuItem>
          <MenuItem value="2">{t(`ticket.statusText.2`)}</MenuItem>
          <MenuItem value="3">{t(`ticket.statusText.3`)}</MenuItem>
          <MenuItem value="4">{t(`ticket.statusText.4`)}</MenuItem>
          <MenuItem value="5">{t(`ticket.statusText.5`)}</MenuItem>
        </Select>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            sx={{ marginRight: 1 }}
          >
            {t("attendance.search")}
          </Button>
          {!auth?.user?.roles.includes("Lab Manager") && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenCreateModal}
            >
              {t("ticket.createTicket")}
            </Button>
          )}
        </Box>
      </Box>

      <TicketList tickets={tickets} onOpenModal={handleOpenModal} />

      {selectedTicketId && (
        <Dialog open={open} onClose={handleCloseModal}>
          <TicketDetailsPage
            id={selectedTicketId}
            onClose={handleCloseModal}
            onUpdateSuccess={handleUpdateSuccess}
          />
        </Dialog>
      )}

      <Modal
        open={createModalOpen}
        onClose={handleCloseCreateModal}
        aria-labelledby="create-ticket-modal"
        aria-describedby="create-ticket-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            overflowY: "auto",
            backgroundColor: "white",
            padding: 3,
            boxShadow: 24,
            borderRadius: 1,
          }}
        >
          <IconButton
            onClick={handleCloseCreateModal}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <CreateTicketPage onCreateSuccess={handleCreateSuccess} />
        </Box>
      </Modal>
    </div>
  );
};

const TicketList = ({ tickets, onOpenModal }) => {
  const { t } = useAuth();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("ticket.title")}</TableCell>
            <TableCell>{t("ticket.description")}</TableCell>
            <TableCell>{t("ticket.creator")}</TableCell>
            <TableCell>{t("ticket.assignee")}</TableCell>
            <TableCell>{t("ticket.createDate")}</TableCell>
            <TableCell>{t("ticket.status")}</TableCell>
            <TableCell>{t("attendance.actions")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.length > 0 ? (
            tickets.map((ticket) => (
              <TableRow key={ticket.id}>
                <TableCell className="truncate truncate-title">
                  {ticket.title}
                </TableCell>
                <TableCell className="truncate truncate-description">
                  {ticket.description}
                </TableCell>
                <TableCell>{ticket.nameCreator}</TableCell>
                <TableCell>{ticket.nameAssignee}</TableCell>
                <TableCell>
                  {new Date(ticket.dateRequire).toLocaleDateString()}
                </TableCell>
                <TableCell
                  sx={{
                    color: getColorStatus(ticket.status),
                    fontWeight: "bold"
                  }}
                >
                  {t(`ticket.statusText.${ticket.status}`)}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onOpenModal(ticket.id)}
                  >
                    {t("ticket.details")}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={7}
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {t("ticket.ticketNotFound")}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// Function to get CSS class based on status
const getColorStatus = (status) => {
  switch (status) {
    case 2:
      return "green";
    case 5:
      return "red";
    default:
      return "skyblue";
  }
};

export default TicketPage;
